import { useNavigate } from "react-router-dom";
import { MdNotInterested } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";

import { Button } from "../button/Button";
import styles from "./Unauthorized.module.css";

const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <div className={styles["unauthorized-container"]}>
      <div className={styles["unauthorized-content"]}>
        <MdNotInterested color="red" size={50} />
        <h1>Access Denied!</h1>
        <Button
          customStyle={{
            display: "flex",
            height: "fit-content",
            width: "fit-content",
            alignItems: "center",
            gap: "10px",
          }}
          onClick={() => navigate(-1)}
        >
          <IoIosArrowBack />
          <span>Back</span>
        </Button>
      </div>
    </div>
  );
};

export { Unauthorized };
