import { ServiceTable } from "../components/table/ServiceTable";

import data from "../mock/userServices.json";

const UserServicesPage = () => {
  const userAuth = window.localStorage.getItem("token");
  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await axiosInstance.get(`/api/user/services`, {
  //         headers: {
  //           Authorization: `Bearer ${userAuth}`,
  //           "Content-Type": "application/json",
  //         },
  //       });
  //       setData(res.data);
  //     } catch (err: any) {
  //       console.log("err", err);
  //       notifyError(err.response.data.message ?? err.response.data);
  //     }
  //   })();
  // }, []);

  // <td>{item.service.name}</td>
  //                 <td>{item.machine.name}</td>
  //                 <td>{item.repair.name}</td>
  //                 <td>{item.service.actions[0].actionType}</td>
  //                 <td>Test22</td>
  //                 <td>{new Date(it

  return <> {data && <ServiceTable userStats data={data} />}</>;
};

export { UserServicesPage };
