import { useEffect, useState } from "react";
import { ServiceTable } from "../components/table/ServiceTable";
import axiosInstance from "../axios-interceptor/axiosInterceptor";
import { notifyError } from "../components/notify";
import { Loading } from "../components/loading/Loading";
import { NoData } from "../components/card/NoData";
import { ToastContainer } from "react-toastify";
import useLocalStorage from "../hooks/useLocalStorage";

const MachineServicesPage = () => {
  const user = useLocalStorage("user");
  const [data, setData] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const res = await axiosInstance.get("/api/service", {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        });
        setData(res.data);
      } catch (err: any) {
        console.log("err", err);
        notifyError(err.response.data.message ?? err.response.data);
      }
    })();
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: "orange", fontFamily: "Arial", fontSize: "14px" }}
      />
      <h1 style={{ textAlign: "center", margin: 0 }}>Services</h1>
      {!data ? (
        <Loading />
      ) : data.length > 0 ? (
        <ServiceTable data={data} />
      ) : (
        <NoData
          title="No services found"
          message="Newly added services should appear on this screen, you can create them in the Service Manager"
        />
      )}
    </>
  );
};

export { MachineServicesPage };
