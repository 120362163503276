import styles from "./Brand.module.css";

interface BrandProps {
  logoPath?: string;
  height?: number;
  width?: number;
}

const Brand = ({
  height,
  width,
  logoPath = "/BoaslotsWhite.svg",
}: BrandProps) => {
  return (
    <div className={styles.navbar__logo_container}>
      <img height={height} width={width} src={logoPath} alt="Logo" />
    </div>
  );
};

export default Brand;
