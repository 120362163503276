import { ReactNode, useEffect, useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";

import style from "./ServiceCard.module.css";
import { Input } from "../input/Input";
import { Button } from "../button/Button";
import { Controller, useForm } from "react-hook-form";
import SearchableDropdown from "../dropdown/SearchableDropdown";
import axiosInstance from "../../axios-interceptor/axiosInterceptor";
import { notifyError, notifySuccess } from "../notify";
import useLocalStorage from "../../hooks/useLocalStorage";

interface ServiceCardProps {
  heading: string;
  description: string;
  children?: ReactNode;
  casinos?: any;
}

export const ServiceCard = ({
  heading,
  description,
  casinos,
}: ServiceCardProps) => {
  const {
    control,
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm();
  const user = useLocalStorage("user");

  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();

      formData.append("name", data.name);
      formData.append("casino", data.casino);
      formData.append("machine", data.machine);
      formData.append("repair", data.repair);
      formData.append("isCritical", data.isCritical);
      formData.append("serviceImage", data.serviceImage[0]);

      if (casinoId) {
        await axiosInstance.post(`/api/service/${casinoId}`, formData, {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        notifySuccess(
          "New service added successfully, check in View machine services!"
        );
      } else {
        notifyError("Something bad happened!");
      }
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  };
  const [valueCasinos, setValueCasinos] = useState("Select casino...");
  const [valueMachines, setValueMachines] = useState("Select machine...");
  const [valueRepairs, setValueRepairs] = useState("Select repair...");
  const [casinoId, setCasinoId] = useState("");

  const [machines, setMachines] = useState<any>();
  const [repairs, setRepairs] = useState<any>();

  const fetchMachines = async (casinoId: string) => {
    try {
      const getMachines = async () => {
        try {
          const response = await axiosInstance.get(
            `/api/machine/casino/${casinoId}`,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
                "Content-Type": "application/json",
              },
            }
          );
          return response.data;
        } catch (err: any) {
          console.log("err", err);
          notifyError(err.response.data.message ?? err.response.data);
        }
      };

      const machines = await getMachines();
      setMachines(machines);
    } catch (err: any) {
      notifyError(err.message);
    }
  };

  const fetchRepairs = async (casinoId: string) => {
    try {
      const getRepairs = async () => {
        try {
          const response = await axiosInstance.get(`/api/repair/${casinoId}`, {
            headers: {
              Authorization: `Bearer ${user.token}`,
              "Content-Type": "application/json",
            },
          });
          return response.data;
        } catch (err: any) {
          console.log("err", err);
          notifyError(err.response.data.message ?? err.response.data);
        }
      };
      const repairs = await getRepairs();
      setRepairs(repairs);
    } catch (err: any) {
      notifyError(err.message);
    }
  };

  useEffect(() => {
    (async () => {
      if (casinoId) {
        await fetchMachines(casinoId);
        await fetchRepairs(casinoId);
      }
    })();

    return () => {};
  }, [casinoId]);

  return (
    <div className={style.card}>
      <div className={style.content}>
        <h2 className={style.title}>{heading}</h2>
        <p className={style.description}>{description}</p>
        <form
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "10px",
          }}
        >
          <label>Enter name of the service</label>
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Please enter title of the service",
            }}
            render={({ field }) => (
              <Input
                formControl={field}
                placeholder="Enter title of the service"
              />
            )}
          />
          {errors.name && (
            <span className="error-message">
              {errors.name.message as string}
            </span>
          )}
          <label>Casino</label>
          <Controller
            name="casino"
            control={control}
            rules={{
              required: "Please select casino",
            }}
            render={({ field }) => (
              <SearchableDropdown
                field={field}
                options={casinos}
                label="name"
                id="_id"
                selectedId={(val: any) => setCasinoId(val)}
                selectedVal={valueCasinos}
                handleChange={(val) => setValueCasinos(val ?? "")}
              />
            )}
          />
          {errors.casino && (
            <span className="error-message">
              {errors.casino.message as string}
            </span>
          )}
          <label>Machine</label>
          <Controller
            name="machine"
            control={control}
            rules={{
              required: "Please select machine",
            }}
            render={({ field }) => (
              <SearchableDropdown
                field={field}
                options={machines}
                label="name"
                id="_id"
                selectedId={() => {}}
                selectedVal={valueMachines}
                handleChange={(val) => setValueMachines(val ?? "")}
              />
            )}
          />
          {errors.machine && (
            <span className="error-message">
              {errors.machine.message as string}
            </span>
          )}
          <label>Service description</label>
          <Controller
            name="repair"
            control={control}
            rules={{
              required: "Please select repair description",
            }}
            render={({ field }) => (
              <SearchableDropdown
                field={field}
                options={repairs}
                label="name"
                id="_id"
                selectedId={() => {}}
                selectedVal={valueRepairs}
                handleChange={(val) => setValueRepairs(val ?? "")}
              />
            )}
          />
          {errors.repair && (
            <span className="error-message">
              {errors.repair.message as string}
            </span>
          )}
          <Controller
            name="isCritical"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <label>Critical</label>
                <input
                  {...field}
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  type="checkbox"
                />
              </div>
            )}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <input
              type="file"
              accept="image/*"
              {...register("serviceImage")}
              style={{ display: "none" }}
              id="imageUploadInput"
            />
            <label
              htmlFor="imageUploadInput"
              style={{
                width: "70%",
                cursor: "pointer",
                background: "green",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "8px",
                borderRadius: "4px",
                color: "white",
                fontSize: "16px",
                marginBottom: "8px",
              }}
            >
              <IoCloudUploadOutline size={20} style={{ marginRight: "8px" }} />
              <span>Upload Image</span>
            </label>
            <span style={{ color: "#2C3E50", marginLeft: "12px" }}>
              {getValues("serviceImage")?.[0]?.name ?? "No file selected"}
            </span>
          </div>

          <Button type="submit">Create Service</Button>
        </form>
      </div>
    </div>
  );
};
