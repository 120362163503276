import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { TbHandClick } from "react-icons/tb";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import ReactPaginate from "react-paginate";

import styles from "./Table.module.css";
import mobileStyles from "./MobileTable.module.css";
import { CasinoModel } from "../../models/Casinos";
import { Button } from "../button/Button";
import { useMobile } from "../../hooks/useMobile";
import axiosInstance from "../../axios-interceptor/axiosInterceptor";
import { notifyError, notifySuccess } from "../notify";
import Modal from "../modal/Modal";
import useLocalStorage from "../../hooks/useLocalStorage";
import { SearchTable } from "../search/SearchTable";

interface CasinoTableProps {
  data: CasinoModel[];
  fetchCasinos: () => void;
}

const CasinoTable = ({ data, fetchCasinos }: CasinoTableProps) => {
  const user = useLocalStorage("user");
  const isMobile = useMobile();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const deletingCasinoIdRef = useRef<string | null>(null);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const filteredData = data.filter((item: any) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const offset = currentPage * itemsPerPage;
  const currentData = filteredData.slice(offset, offset + itemsPerPage);

  const handleCasinoDelete = async (casinoId: string) => {
    try {
      await axiosInstance.delete(`/api/casino/${casinoId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Casino deleted successfully!");
      fetchCasinos();
    } catch (err: any) {
      notifyError(err.response.data.message ?? err.response.data);
    }
  };

  const confirmDelete = () => {
    if (deletingCasinoIdRef.current) {
      handleCasinoDelete(deletingCasinoIdRef.current);
      setOpenDelete(false);
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: "orange", fontFamily: "Arial", fontSize: "14px" }}
      />
      <SearchTable
        placeholder="Search by casino name"
        field="name"
        data={filteredData}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <div className={styles.container}>
        {isMobile ? (
          currentData.map((casino: any) => (
            <table key={casino._id} className={mobileStyles.container}>
              <thead>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <th colSpan={2} className={mobileStyles.valueCell}>
                    Casino Information
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={mobileStyles.headerCell}>Name</td>
                  <td className={mobileStyles.valueCell}>{casino.name}</td>
                </tr>
                <tr className={mobileStyles.row}>
                  <td className={mobileStyles.headerCell}>Members</td>
                  <td>
                    {casino.members &&
                      casino.members.map((member: any) => (
                        <div
                          className={mobileStyles.valueCell}
                          key={member._id}
                        >
                          {user.username === member.username ? (
                            <span>
                              <strong>{member.username}</strong>
                            </span>
                          ) : (
                            <a
                              className={mobileStyles.link}
                              style={{ color: "blue", fontWeight: "bold" }}
                              href={`/user/${member._id}`}
                            >
                              {member.username}
                            </a>
                          )}
                        </div>
                      ))}
                  </td>
                </tr>
                <tr>
                  <td className={mobileStyles.headerCell}>Machines</td>
                  <td style={{ padding: "20px" }}>
                    {casino.machines && casino.machines.length > 0 ? (
                      casino.machines.map((machine: any) => (
                        <div
                          key={machine._id}
                          className={mobileStyles.machineContainer}
                          style={{
                            backgroundColor: machine.needsRepair
                              ? "red"
                              : "green",
                          }}
                        >
                          <a
                            className={mobileStyles.link}
                            style={{ color: "white" }}
                            href={`/machine/${machine._id}`}
                          >
                            {machine.name}
                          </a>
                        </div>
                      ))
                    ) : (
                      <div className={mobileStyles.valueCell}>
                        <span>No machines</span>
                      </div>
                    )}
                  </td>
                </tr>
                <tr className={mobileStyles.row}>
                  <td className={mobileStyles.headerCell}>Deleted</td>
                  <td className={mobileStyles.valueCell}>
                    {casino.isDeleted ? "Yes" : "No"}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className={mobileStyles.buttonsContainer}>
                      <Button
                        onClick={() => navigate(`/casino/${casino._id}`)}
                        className={styles["button-edit"]}
                      >
                        Manage
                      </Button>
                      <Button
                        disabled={casino.isDeleted}
                        onClick={() => {
                          setOpenDelete(true);
                          deletingCasinoIdRef.current = casino._id;
                        }}
                        className={styles["button-delete"]}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          ))
        ) : (
          <table className={styles.table}>
            <thead>
              <tr className={styles.headerRow}>
                <th>Name</th>
                <th>
                  Members <TbHandClick />
                </th>
                <th>
                  Machines <TbHandClick />
                </th>
                <th>Deleted</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((casino: CasinoModel) => (
                <tr key={casino._id} className={styles.dataRow}>
                  <td>{casino.name}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                        fontWeight: "bold",
                        gap: "12px",
                      }}
                    >
                      {casino.members &&
                        casino.members.map((member: any, index) => (
                          <div key={index}>
                            <a
                              style={{ textDecoration: "none" }}
                              href={`/user/${member._id}`}
                            >
                              {member.username}
                            </a>
                          </div>
                        ))}
                    </div>
                  </td>
                  <td>
                    {casino.machines && casino.machines.length > 0 ? (
                      casino.machines.map((machine: any) => (
                        <div
                          key={machine._id}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                            margin: "10px 0px",
                            padding: "4px",
                            borderRadius: "4px",
                            backgroundColor: machine.needsRepair
                              ? "red"
                              : "green",
                          }}
                        >
                          <a
                            style={{ textDecoration: "none", color: "white" }}
                            href={`/machine/${machine._id}`}
                          >
                            {machine.name}
                          </a>
                          {machine.isDeleted && <FaRegTrashCan color="white" />}
                        </div>
                      ))
                    ) : (
                      <div className={mobileStyles.valueCell}>
                        <span>No machines</span>
                      </div>
                    )}
                  </td>
                  <td>{casino.isDeleted ? "Yes" : "No"}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px",
                        gap: "10px",
                      }}
                    >
                      <Button
                        onClick={() => navigate(`/casino/${casino._id}`)}
                        className={styles["button-edit"]}
                      >
                        Manage
                      </Button>
                      <Button
                        disabled={casino.isDeleted}
                        onClick={() => {
                          setOpenDelete(true);
                          deletingCasinoIdRef.current = casino._id;
                        }}
                        className={styles["button-delete"]}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <ReactPaginate
        previousLabel={
          <Button className={styles["paggination-buttons"]}>
            <IoChevronBackOutline />
          </Button>
        }
        nextLabel={
          <Button className={styles["paggination-buttons"]}>
            <IoChevronForwardOutline />
          </Button>
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={styles.pagination}
        activeClassName={styles.active}
        pageClassName={styles.page}
        previousClassName={styles.previous}
        nextClassName={styles.next}
        breakLinkClassName={styles.breakLink}
      />
      {openDelete && (
        <Modal
          isOpen={openDelete}
          setIsOpen={setOpenDelete}
          modalHeader="Are you sure you want to delete this casino?"
          onConfirm={confirmDelete}
          leftButtonText="Confirm"
          rightButtonText="Cancel"
        />
      )}
    </>
  );
};

export { CasinoTable };
