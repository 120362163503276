import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";

import styles from "./Login.module.css";
import Brand from "../brand/Brand";
import { Button } from "../button/Button";
import axiosInstance from "../../axios-interceptor/axiosInterceptor";
import { FRONT_ROUTES } from "../../routes/routes";
import { notifyError } from "../notify";

function Login() {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const roleDefaultRoutes: { [key: string]: string } = {
    superadmin: FRONT_ROUTES.user,
    admin: FRONT_ROUTES.casino,
    servicer: FRONT_ROUTES.home,
    accountant: FRONT_ROUTES.service,
  };

  const handleLogin = async (data: any) => {
    try {
      const res = await axiosInstance.post("/api/auth/login", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status === 200) {
        window.localStorage.setItem("user", JSON.stringify(res.data));
        window.localStorage.setItem("token", res.data.token);
        const defaultRoute = roleDefaultRoutes[res.data.role];
        navigate(defaultRoute, { replace: true });
      }
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: "orange", fontFamily: "Arial", fontSize: "14px" }}
      />
      <div className={styles["login-page"]}>
        <div className={styles["login-container"]}>
          <h2 className={styles["login-header"]}>
            <Brand height={80} width={250} />
          </h2>
          <form
            className={styles["login-form"]}
            onSubmit={handleSubmit(handleLogin)}
          >
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <input
                  {...field}
                  className={styles["login-input"]}
                  placeholder="Email"
                  type="email"
                  id="email"
                  name="email"
                />
              )}
            />
            {errors.email && (
              <span className="error-message">
                {errors.email.message as string}
              </span>
            )}

            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: "Password is required",
              }}
              render={({ field }) => (
                <input
                  {...field}
                  className={styles["login-input"]}
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                />
              )}
            />
            {errors.password && (
              <span className="error-message">
                {errors.password.message as string}
              </span>
            )}
            <a href="/forgot-password" className={styles["forgot-password"]}>
              Forgot Password?
            </a>
            <Button type="submit">Login</Button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
