import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./ResetPassword.module.css";
import Brand from "../brand/Brand";
import { Button } from "../button/Button";
import axiosInstance from "../../axios-interceptor/axiosInterceptor";
import { notifyError, notifySuccess } from "../notify";
import { ToastContainer } from "react-toastify";
import { FRONT_ROUTES } from "../../routes/routes";

const ResetPassword = () => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  const { id, token } = useParams();

  const resetPassword = async (data: any) => {
    if (password !== confirmPassword) {
      notifyError("Passwords do not match!");
      return;
    }

    try {
      const response = await axiosInstance.post(
        `/auth/reset-password/${id}/${token}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        notifySuccess("Password changed successfully!");
      }
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  };
  const handleBackToLogin = () => {
    navigate(FRONT_ROUTES.login);
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: "orange", fontFamily: "Arial", fontSize: "14px" }}
      />
      <div className={styles["reset-password-page"]}>
        <div className={styles["reset-password-container"]}>
          <h2 className={styles["reset-password-header"]}>
            <Brand height={80} width={250} />
          </h2>
          <form
            className={styles["reset-password-form"]}
            onSubmit={handleSubmit(resetPassword)}
          >
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: "Password is required",
                validate: (value) =>
                  value === confirmPassword || "Passwords do not match",
              }}
              render={({ field }) => (
                <>
                  <input
                    {...field}
                    className={styles["reset-password-input"]}
                    placeholder="Password"
                    type="password"
                    id="password"
                    name="password"
                  />
                </>
              )}
            />
            {errors.password && (
              <span className="error-message">
                {errors.password.message as string}
              </span>
            )}

            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              rules={{
                required: "Confirm password is required",
                validate: (value) =>
                  value === password || "Passwords do not match",
              }}
              render={({ field }) => (
                <>
                  <input
                    {...field}
                    className={styles["reset-password-input"]}
                    placeholder="Confirm password"
                    type="password"
                    id="confirm-password"
                    name="confirm-password"
                  />
                </>
              )}
            />
            {errors.confirmPassword && (
              <span className="error-message">
                {errors.confirmPassword.message as string}
              </span>
            )}
            <Button type="submit">Change Password</Button>
          </form>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button onClick={handleBackToLogin}>Back to Login</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
