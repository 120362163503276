import React, { useState, useEffect } from "react";

import axiosInstance from "../../axios-interceptor/axiosInterceptor";
import FullPictureViewer from "../magnifier/FullPictureViewer";
import { Loading } from "../loading/Loading";
import useLocalStorage from "../../hooks/useLocalStorage";

interface ImageDisplayProps {
  serviceId: string;
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({ serviceId }) => {
  const user = useLocalStorage("user");
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/service/image/${serviceId}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
            responseType: "blob", // Set responseType to 'blob' to receive binary data
          }
        );

        if (response.status >= 200 && response.status < 300) {
          const url = URL.createObjectURL(response.data);
          setImageUrl(url);
        } else {
          console.error("Failed to fetch image:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();

    // Clean up by revoking the object URL when the component unmounts
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [serviceId]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        imageUrl && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <span>Click to see full picture</span>
            <FullPictureViewer imageUrl={imageUrl} alt="Slot defect" />
          </div>
        )
      )}
    </div>
  );
};

export default ImageDisplay;
