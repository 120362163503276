import { useMemo } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { FRONT_ROUTES } from "../routes/routes";
import useLocalStorage from "./useLocalStorage";

const ProtectedRoutes = () => {
  const user = useLocalStorage("user");
  const location = useLocation();

  const isAuthenticated = Boolean(user);

  const allowedRoutes = useMemo(() => {
    if (!user) return [];
    return user.allowedRoutes || [];
  }, [user]);

  if (!isAuthenticated) {
    return (
      <Navigate to={FRONT_ROUTES.login} replace state={{ from: location }} />
    );
  }

  const routeMatch: any = Object.entries(FRONT_ROUTES).find(([key, value]) => {
    const routeRegex = new RegExp(`^${value.replace(/:\w+/g, "([^/]+)")}$`);
    return routeRegex.test(location.pathname);
  });

  const current = Object.values(FRONT_ROUTES).find(
    (val) => routeMatch[1] === val
  );

  const currentRouteKey: any = Object.keys(FRONT_ROUTES).find(
    (key) => FRONT_ROUTES[key] === current
  );

  if (!currentRouteKey) {
    return (
      <Navigate
        to={FRONT_ROUTES.pageNotFound}
        replace
        state={{ from: location }}
      />
    );
  }

  const hasAccess = allowedRoutes.includes(currentRouteKey);

  if (!hasAccess) {
    return (
      <Navigate
        to={FRONT_ROUTES.unauthorized}
        replace
        state={{ from: location }}
      />
    );
  }

  return <Outlet />;
};

export default ProtectedRoutes;
