import { ReactNode } from "react";

interface IconButtonProps {
  children: ReactNode;
  buttonType?: "submit" | "reset" | "button" | undefined;
  style?: Object;
  onClick?: () => void;
  formControl?: any;
}

const IconButton = ({
  children,
  style,
  buttonType,
  formControl,
  onClick,
}: IconButtonProps) => {
  return (
    <button
      {...formControl}
      type={buttonType}
      style={{
        display: "flex",
        padding: "10px",
        gap: "10px",
        fontSize: "20px",
        cursor: "pointer",
        color: "white",
        background: "none",
        border: "none",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export { IconButton };
