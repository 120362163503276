import { useState } from "react";
import ReactPaginate from "react-paginate";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";

import styles from "./Table.module.css";
import mobileStyles from "./MobileTable.module.css";
import { useMobile } from "../../hooks/useMobile";
import { SearchTable } from "../search/SearchTable";
import { Button } from "../button/Button";

const ServiceTable = ({ data, userStats }: any) => {
  const isMobile = useMobile();
  const [searchTerm, setSearchTerm] = useState("");

  const findCreator = (item: any) => {
    return item.actions.map((action: any) => {
      if (action.actionType === "created") return action.user.username;
    });
  };

  const lastAction = (item: any) => {
    return item.actions[item.actions.length - 1].actionType;
  };

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const filteredData = data.filter((item: any) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const offset = currentPage * itemsPerPage;
  const currentData = filteredData.slice(offset, offset + itemsPerPage);

  // add filters for - confirmed, rejected, done, in progress
  return (
    <>
      <SearchTable
        placeholder="Search by service name"
        field="name"
        data={filteredData}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <div className={styles.container}>
        {isMobile ? (
          currentData.map((service: any) => (
            <table key={service._id} className={mobileStyles.container}>
              <thead>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <th colSpan={2} className={mobileStyles.valueCell}>
                    Service Information
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={mobileStyles.headerCell}>Service</td>
                  <td className={mobileStyles.valueCell}>
                    <a href={`/service/${service._id}`}>{service.name}</a>
                  </td>
                </tr>
                <tr>
                  <td className={mobileStyles.headerCell}>Machine</td>
                  <td className={mobileStyles.valueCell}>
                    <a href={`/machine/${service.machine._id}`}>
                      {service.machine.name}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className={mobileStyles.headerCell}>
                    Repair Description
                  </td>
                  <td className={mobileStyles.valueCell}>
                    {service.repair.name}
                  </td>
                </tr>
                <tr>
                  <td className={mobileStyles.headerCell}>Service Status</td>
                  <td className={mobileStyles.valueCell}>
                    {lastAction(service)}
                  </td>
                </tr>
                {!userStats && (
                  <tr>
                    <td className={mobileStyles.headerCell}>Created by</td>
                    <td className={mobileStyles.valueCell}>
                      {findCreator(service)}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={mobileStyles.headerCell}>Created at</td>
                  <td className={mobileStyles.valueCell}>
                    {new Date(service.createdAt).toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </table>
          ))
        ) : (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Service</th>
                <th>Machine</th>
                <th>Repair Description</th>
                <th>Service Status</th>
                {!userStats && <th>Created by</th>}
                <th>Created at</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item: any, index: number) => (
                <tr key={item._id + index}>
                  <td>
                    <a
                      style={{ textDecoration: "none" }}
                      href={`/service/${item._id}`}
                    >
                      {item.name}
                    </a>
                  </td>
                  <td>{item.machine.name}</td>
                  <td>{item.repair.name}</td>
                  <td>{lastAction(item)}</td>
                  {!userStats && <td>{findCreator(item)}</td>}
                  <td>{new Date(item.createdAt).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <ReactPaginate
        previousLabel={
          <Button className={styles["paggination-buttons"]}>
            <IoChevronBackOutline />
          </Button>
        }
        nextLabel={
          <Button className={styles["paggination-buttons"]}>
            <IoChevronForwardOutline />
          </Button>
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={styles.pagination}
        activeClassName={styles.active}
        pageClassName={styles.page}
        previousClassName={styles.previous}
        nextClassName={styles.next}
        breakLinkClassName={styles.breakLink}
      />
    </>
  );
};

export { ServiceTable };
