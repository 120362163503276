import { useMobile } from "../../hooks/useMobile";
import { Button } from "../button/Button";
import styles from "./Table.module.css";
import mobileStyles from "./MobileTable.module.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const UserTable = ({ data }: any) => {
  const isMobile = useMobile();
  const navigate = useNavigate();

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: "orange", fontFamily: "Arial", fontSize: "14px" }}
      />
      <div className={styles.container}>
        {isMobile ? (
          data.map((user: any) => (
            <table key={user._id} className={mobileStyles.container}>
              <tbody>
                <tr className={mobileStyles.row}>
                  <td className={mobileStyles.headerCell}>Username</td>
                  <td className={mobileStyles.valueCell}>{user.username}</td>
                </tr>
                <tr>
                  <td className={mobileStyles.headerCell}>Email</td>
                  <td className={mobileStyles.valueCell}>{user.email}</td>
                </tr>
                <tr>
                  <td className={mobileStyles.headerCell}>Role</td>
                  <td className={mobileStyles.valueCell}>{user.role}</td>
                </tr>
                <tr>
                  <td className={mobileStyles.headerCell}>Status</td>
                  <td className={mobileStyles.valueCell}>
                    {user.isDeleted
                      ? "Deleted"
                      : user.isBlocked
                      ? "Blocked"
                      : "Valid"}
                  </td>
                </tr>
                <tr>
                  <td className={mobileStyles.headerCell}>Actions</td>
                  <td className={mobileStyles.valueCell}>
                    <Button
                      onClick={() => navigate(`/user/${user._id}`)}
                      className={styles["button-edit"]}
                    >
                      Manage
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          ))
        ) : (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((user: any, index: number) => (
                <tr key={user._id + index}>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>
                    {user.isDeleted
                      ? "Deleted"
                      : user.isBlocked
                      ? "Blocked"
                      : "Valid"}
                  </td>

                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        onClick={() => navigate(`/user/${user._id}`)}
                        className={styles["button-edit"]}
                      >
                        Manage
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export { UserTable };
