import { ReactNode } from "react";
import { Button } from "../button/Button";
import style from "./Modal.module.css";

interface ModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  modalHeader: string;
  modalDescription?: string;
  onConfirm?: () => void;
  children?: ReactNode;
  leftButtonText?: string;
  rightButtonText?: string;
}

const Modal = ({
  modalHeader,
  modalDescription,
  isOpen,
  children,
  leftButtonText = "Add",
  rightButtonText = "Cancel",
  onConfirm,
  setIsOpen,
}: ModalProps) => {
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isOpen && (
        <div className={style.modal}>
          <div className={style["modal-container"]}>
            <div className={style["modal-content"]}>
              <span className={style.header}>{modalHeader}</span>
              <p className={style.description}>{modalDescription}</p>
              <form
                id="modal-form"
                onSubmit={onConfirm}
                className={style.children}
              >
                {children}
              </form>
              <div className={style["modal-buttons"]}>
                <Button
                  type="submit"
                  form="modal-form"
                  customStyle={{ background: "green", width: "100px" }}
                >
                  {leftButtonText}
                </Button>
                <Button
                  onClick={toggleModal}
                  customStyle={{ background: "red", width: "100px" }}
                >
                  {rightButtonText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
