import React from "react";
import { useMobile } from "../../hooks/useMobile";

interface NoDataProps {
  title: string;
  message: string;
}

const NoData: React.FC<NoDataProps> = ({ title, message }) => {
  const isMobile = useMobile();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: isMobile ? "50px 20px" : "0",
        height: "50vh",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          background: "rgba(255, 165, 0, 0.2)",
          borderRadius: "8px",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: isMobile ? "30px" : "50px",
          maxWidth: isMobile ? "300px" : "400px",
        }}
      >
        <span style={{ fontSize: "1.5em", marginBottom: "20px" }}>{title}</span>

        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <img height={70} width={70} src="/magnify.svg" alt="Magnify icon" />
        </div>

        <span style={{ fontSize: "1em" }}>{message}</span>
      </div>
    </div>
  );
};

export { NoData };
