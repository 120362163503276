import { Suspense } from "react";
import { Views } from "./Views";
import { Loading } from "./components/loading/Loading";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Views />
    </Suspense>
  );
}

export default App;
