import style from "./Loading.module.css";

type LoadingProps = {
  textColor?: string;
};

const Loading = ({ textColor = "black" }: LoadingProps) => {
  return (
    <div className={`${style["race-flag-spinner"]} ${style["loading-text"]}`}>
      <div className={style.loader}></div>
      <p
        style={{
          color: textColor,
        }}
      >
        Loading...
      </p>
    </div>
  );
};

export { Loading };
