import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Controller, useForm } from "react-hook-form";

import style from "../styles/HomePage.module.css";
import { Button } from "../components/button/Button";
import { ServiceCard } from "../components/card/ServiceCard";
import Modal from "../components/modal/Modal";
import { Input } from "../components/input/Input";
import axiosInstance from "../axios-interceptor/axiosInterceptor";
import { notifyError, notifySuccess } from "../components/notify";
import SearchableDropdown from "../components/dropdown/SearchableDropdown";
import useLocalStorage from "../hooks/useLocalStorage";

interface HomePageProps {
  isAdmin?: boolean;
}

const HomePage = ({ isAdmin = true }: HomePageProps) => {
  const user = useLocalStorage("user");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [openNewMachine, setOpenNewMachine] = useState(false);
  const [openNewDescription, setOpenNewDescription] = useState(false);
  const [casinos, setCasinos] = useState<any>();
  const [valueCasinos, setValueCasinos] = useState("Select casino...");

  const handleOpenNewMachineModal = () => {
    setOpenNewMachine(!openNewMachine);
  };

  const handleOpenNewDescriptionModal = () => {
    setOpenNewDescription(!openNewDescription);
  };

  const handleAddMachine = async (data: any) => {
    try {
      await axiosInstance.post(`/api/machine/${data.casino}`, data, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Machine added successfully!");
      handleOpenNewMachineModal();
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  };

  const handleAddRepairDescription = async (data: any) => {
    try {
      await axiosInstance.post(`/api/repair/${data.casino}`, data, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Repair description added successfully!");
      handleOpenNewDescriptionModal();
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  };

  const fetchCasinos = async () => {
    try {
      const getUserCasinos = async () => {
        try {
          const response = await axiosInstance.get("/api/casino", {
            headers: {
              Authorization: `Bearer ${user.token}`,
              "Content-Type": "application/json",
            },
          });
          return response.data;
        } catch (err: any) {
          console.log("err", err);
          notifyError(err.response.data.message ?? err.response.data);
        }
      };

      const casinos = await getUserCasinos();
      setCasinos(casinos);
    } catch (err: any) {
      notifyError(err.message);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchCasinos();
    })();
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: "green", fontFamily: "Arial", fontSize: "14px" }}
      />
      <div
        style={{
          margin: "50px 0",
        }}
      >
        {isAdmin ? (
          <div className={style.wrapper}>
            <div className={style.container}>
              <ServiceCard
                heading="Create a service"
                description="Fill in the input fields to create a service"
                casinos={casinos}
              />
            </div>
            <div className={style.container}>
              <h1>Options</h1>
              <Button
                customStyle={{ width: "300px" }}
                onClick={handleOpenNewMachineModal}
              >
                Add new machine
              </Button>
              <Button
                customStyle={{ width: "300px" }}
                onClick={handleOpenNewDescriptionModal}
              >
                Add new service description
              </Button>
            </div>
          </div>
        ) : (
          <div className={style.container}>
            <ServiceCard
              heading="Create a service"
              description="Fill in the input fields to create a service"
              casinos={casinos}
            />
          </div>
        )}
        {openNewMachine && (
          <Modal
            modalHeader="Add new machine"
            modalDescription="Fill in the fields to add new machine"
            isOpen={openNewMachine}
            setIsOpen={setOpenNewMachine}
            onConfirm={handleSubmit(handleAddMachine)}
          >
            <Controller
              name="casino"
              control={control}
              rules={{
                required: "Please select casino",
              }}
              render={({ field }) => (
                <SearchableDropdown
                  field={field}
                  options={casinos}
                  label="name"
                  id="_id"
                  selectedVal={valueCasinos}
                  handleChange={(val) => setValueCasinos(val ?? "")}
                />
              )}
            />
            {errors.casino && (
              <span className="error-message">
                {errors.casino.message as string}
              </span>
            )}
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Please enter the name of the machine",
              }}
              render={({ field }) => (
                <Input
                  formControl={field}
                  placeholder="Enter the name of the machine"
                />
              )}
            />
            {errors.name && (
              <span className="error-message">
                {errors.name.message as string}
              </span>
            )}
          </Modal>
        )}
        {openNewDescription && (
          <Modal
            modalHeader="Add new repair description"
            modalDescription="Fill in the fields to add new repair description"
            isOpen={openNewDescription}
            setIsOpen={setOpenNewDescription}
            onConfirm={handleSubmit(handleAddRepairDescription)}
          >
            <Controller
              name="casino"
              control={control}
              rules={{
                required: "Please select casino",
              }}
              render={({ field }) => (
                <SearchableDropdown
                  field={field}
                  options={casinos}
                  label="name"
                  id="_id"
                  selectedVal={valueCasinos}
                  handleChange={(val) => setValueCasinos(val ?? "")}
                />
              )}
            />
            {errors.casino && (
              <span className="error-message">
                {errors.casino.message as string}
              </span>
            )}
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Enter new repair name",
              }}
              render={({ field }) => (
                <Input
                  formControl={field}
                  placeholder="Enter the name of the repair"
                />
              )}
            />
            {errors.name && (
              <span className="error-message">
                {errors.name.message as string}
              </span>
            )}
          </Modal>
        )}
      </div>
    </>
  );
};

// When clicking create new service, a popup will appear to see the created service
// in pending screen

export { HomePage };
