interface State {
  showRole: boolean;
  showBlock: boolean;
  showNew?: boolean;
  showDelete?: boolean;
}

type Action =
  | { type: "toggleShowRole" }
  | { type: "toggleShowBlock" }
  | { type: "toggleShowDelete" }
  | { type: "toggleShowNew" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "toggleShowRole":
      return {
        ...state,
        showDelete: false,
        showRole: !state.showRole,
        showBlock: false,
        showNew: false,
      };
    case "toggleShowBlock":
      return {
        ...state,
        showDelete: false,
        showRole: false,
        showBlock: !state.showBlock,
        showNew: false,
      };
    case "toggleShowDelete":
      return {
        ...state,
        showDelete: !state.showDelete,
        showRole: false,
        showBlock: false,
        showNew: false,
      };
    case "toggleShowNew":
      return {
        ...state,
        showDelete: false,
        showRole: false,
        showBlock: false,
        showNew: !state.showNew,
      };
    default:
      return state;
  }
};

export { reducer, type State, type Action };
