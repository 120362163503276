import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useLocalStorage from "../hooks/useLocalStorage";
import axiosInstance from "../axios-interceptor/axiosInterceptor";
import { notifyError } from "../components/notify";
import { ToastContainer } from "react-toastify";
import { NoData } from "../components/card/NoData";
import { Loading } from "../components/loading/Loading";
import { SingleMachineServicesTable } from "../components/table/SingleMachineServicesTable";
import { IoIosArrowBack } from "react-icons/io";
import { Button } from "../components/button/Button";

const ServicesForMachinePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [services, setServices] = useState<any>();
  const user = useLocalStorage("user");

  const fetchMachineServices = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/api/machine/${id}/services`, {
        headers: {
          Authorization: `Bearer ${user.token}`,

          "Content-Type": "application/json",
        },
      });
      setServices(response.data);
    } catch (error: any) {
      console.error("Error fetching machine details:", error);
      notifyError(error.response.data.message ?? error.response.data);
    }
  }, [id, user]);

  useEffect(() => {
    (async () => {
      await fetchMachineServices();
    })();
  }, [fetchMachineServices]);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: "orange", fontFamily: "Arial", fontSize: "14px" }}
      />
      {!services ? (
        <Loading />
      ) : services.length > 0 ? (
        <SingleMachineServicesTable data={services} />
      ) : (
        <>
          <Button
            customStyle={{
              display: "flex",
              height: "fit-content",
              width: "fit-content",
              alignItems: "center",
              gap: "6px",
              margin: "30px",
            }}
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack />
            <span>Back</span>
          </Button>
          <NoData
            title="No services found"
            message="Newly added services should appear on this screen, you can create them in the Service Manager"
          />
        </>
      )}
    </>
  );
};

export { ServicesForMachinePage };
