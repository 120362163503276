import { useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IoIosArrowBack } from "react-icons/io";

import { Button } from "../components/button/Button";
import Modal from "../components/modal/Modal";
import { notifyError, notifySuccess } from "../components/notify";
import { Loading } from "../components/loading/Loading";
import axiosInstance from "../axios-interceptor/axiosInterceptor";
import { MachinesModel } from "../models/Machines";
import styles from "../styles/MachineDetails.module.css";
import SearchableDropdown from "../components/dropdown/SearchableDropdown";
import { Input } from "../components/input/Input";
import useLocalStorage from "../hooks/useLocalStorage";
import { systemRoles } from "../helper/roles";
import { NoData } from "../components/card/NoData";

const MachineDetailsPage = () => {
  const user = useLocalStorage("user");
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [machine, setMachine] = useState<MachinesModel>();
  const [casinos, setCasinos] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCasinoModal, setShowCasinoModal] = useState(false);
  const [changeName, setChangeName] = useState(false);

  const [valueCasinos, setValueCasinos] = useState("Select casino...");

  const fetchMachineDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/api/machine/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      setMachine(response.data);
      setLoading(false);
    } catch (error: any) {
      console.error("Error fetching machine details:", error);
      notifyError(error.response.data.message ?? error.response.data);
      setLoading(false);
    }
  }, [id, user]);

  const updateMachine = async (data: any) => {
    try {
      await axiosInstance.put(`/api/machine/${id}`, data, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Machine updated successfully!");
      setChangeName(false);
      fetchMachineDetails();
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  };

  const handleDeleteMachine = async () => {
    try {
      await axiosInstance.delete(`/api/machine/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Machine deleted successfully!");
      setShowModal(false);
      navigate("/casino");
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  };

  const handleRetrieveMachine = async () => {
    try {
      await axiosInstance.get(`/api/machine/retrieve/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Machine retrieved successfully!");
      fetchMachineDetails();
    } catch (error: any) {
      console.error("Error undeleting casino:", error);
      notifyError(error.response.data.message ?? error.response.data);
    }
  };

  const handleMoveMachine = async (data: any) => {
    try {
      await axiosInstance.get(`/api/machine/${id}/casino/${data.casino}/move`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Machine retrieved successfully!");
      setShowCasinoModal(false);
      fetchMachineDetails();
    } catch (error: any) {
      console.error("Error undeleting casino:", error);
      notifyError(error.response.data.message ?? error.response.data);
    }
  };

  const fetchCasinos = useCallback(async () => {
    try {
      const res = await axiosInstance.get("/api/casino", {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      const filterCurrentCasino = res.data.filter(
        (casino: any) =>
          casino._id.toString() !== machine?.casino._id.toString()
      );
      setCasinos(filterCurrentCasino);
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  }, [user, machine]);

  useEffect(() => {
    fetchMachineDetails();
  }, []);

  useEffect(() => {
    if (showCasinoModal) {
      fetchCasinos();
    }
  }, [showCasinoModal]);

  if (loading) {
    return <Loading />;
  }

  if (!machine) {
    return (
      <NoData
        title="Machine not found"
        message="The machine you are trying to reach might be deleted."
      />
    );
  }

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: "orange", fontFamily: "Arial", fontSize: "14px" }}
      />
      <div className={styles.container}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "30px",
          }}
        >
          <h2 className={styles.heading}>Machine Details</h2>
          <Button
            customStyle={{
              display: "flex",
              height: "fit-content",
              width: "fit-content",
              alignItems: "center",
              gap: "6px",
            }}
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack />
            <span>Back</span>
          </Button>
        </div>

        {machine && (
          <div className={styles.details}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr auto",
                gridTemplateRows: "auto auto",
                gap: "12px",
                columnGap: "50px",
              }}
            >
              <strong>Name</strong>
              <span>{machine.name}</span>
              {!machine.isDeleted && !changeName && (
                <Button
                  onClick={() => setChangeName(true)}
                  customStyle={{ gridColumn: "span 2" }}
                >
                  Change Name
                </Button>
              )}
            </div>

            {changeName && (
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
                onSubmit={handleSubmit(updateMachine)}
              >
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: "Please enter new name of the machine",
                  }}
                  render={({ field }) => (
                    <Input formControl={field} placeholder="Rename machine" />
                  )}
                />
                {errors.name && (
                  <span className="error-message">
                    {errors.name.message as string}
                  </span>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Button customStyle={{ width: "100%" }} type="submit">
                    Confirm
                  </Button>
                  <Button
                    customStyle={{ width: "100%", background: "red" }}
                    onClick={() => setChangeName(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <strong>Owned by</strong>
              {user.role === systemRoles.servicer ? (
                <h3>{machine.casino.name}</h3>
              ) : (
                <Button
                  customStyle={{
                    backgroundColor: "#E97451",
                  }}
                  onClick={() => navigate(`/casino/${machine.casino._id}`)}
                >
                  {machine.casino.name}
                </Button>
              )}
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <strong>Needs repair</strong>
              <span>
                {machine.needsRepair ? (
                  <span style={{ color: "red" }}>Yes</span>
                ) : (
                  <span style={{ color: "green" }}>No</span>
                )}
              </span>
            </div>
            {(user.role === systemRoles.admin ||
              user.role === systemRoles.superadmin) && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label>Move machine to another casino</label>
                <Button
                  onClick={() => {
                    setShowCasinoModal(true);
                  }}
                >
                  Choose casino
                </Button>
              </div>
            )}
            <div>
              <Button
                customStyle={{
                  width: "100%",
                }}
                onClick={() => navigate(`/machine/${id}/services`)}
              >
                See machine services
              </Button>
            </div>
            {machine?.isDeleted ? (
              <Button
                customStyle={{
                  width: "100%",
                }}
                onClick={handleRetrieveMachine}
              >
                Retrieve machine
              </Button>
            ) : (
              <Button
                customStyle={{
                  width: "100%",
                  backgroundColor: "red",
                }}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Delete
              </Button>
            )}
          </div>
        )}
      </div>
      {showCasinoModal && (
        <Modal
          isOpen={showCasinoModal}
          setIsOpen={setShowCasinoModal}
          modalHeader="Select a casino to move machine"
          modalDescription="You can choose from the casinos where you are admin. To be able to perform this action you must be a part of at least 2 casinos!"
          onConfirm={handleSubmit(handleMoveMachine)}
          leftButtonText="Confirm"
          rightButtonText="Cancel"
        >
          <Controller
            name="casino"
            control={control}
            rules={{
              required: "Please select casino",
            }}
            render={({ field }) => (
              <SearchableDropdown
                field={field}
                options={casinos}
                label="name"
                id="_id"
                selectedVal={valueCasinos}
                handleChange={(val) => setValueCasinos(val ?? "")}
              />
            )}
          />
          {errors.casino && (
            <span className="error-message">
              {errors.casino.message as string}
            </span>
          )}
        </Modal>
      )}
      {showModal && (
        <Modal
          isOpen={showModal}
          setIsOpen={setShowModal}
          modalHeader="Are you sure you want to delete this machine?"
          onConfirm={handleDeleteMachine}
          leftButtonText="Confirm"
          rightButtonText="Cancel"
        />
      )}
    </>
  );
};

export { MachineDetailsPage };
