import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axiosInstance from "../axios-interceptor/axiosInterceptor";
import { notifyError } from "../components/notify";
import { ToastContainer } from "react-toastify";
import { Loading } from "../components/loading/Loading";
import { NoData } from "../components/card/NoData";
import { ServiceDetailsCard } from "../components/card/ServiceDetailsCard";
import useLocalStorage from "../hooks/useLocalStorage";

const ServiceDetailsPage = () => {
  const user = useLocalStorage("user");
  const { id } = useParams();
  const [data, setData] = useState<any>();

  const fetchService = useCallback(async () => {
    try {
      const res = await axiosInstance.get(`/api/service/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      setData(res.data);
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  }, [user, id]);

  useEffect(() => {
    (async () => {
      await fetchService();
    })();
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: "orange", fontFamily: "Arial", fontSize: "14px" }}
      />
      {!data ? (
        <Loading />
      ) : data ? (
        <ServiceDetailsCard data={data} refetchData={fetchService} />
      ) : (
        <NoData title="Services not found" message="" />
      )}
    </>
  );
};

export { ServiceDetailsPage };
