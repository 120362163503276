import { useMobile } from "../hooks/useMobile";
import { Support } from "../components/Support";
import { AuthCard } from "../components/auth/AuthCard";
import styles from "../styles/AccountPage.module.css";

const AccountPage = () => {
  //add ability to add new user to the app
  //add ability to assign roles
  const isMobile = useMobile();
  // get the user role from auth context here

  return (
    <div className={isMobile ? styles["container-mobile"] : styles.container}>
      <AuthCard />
      <Support />
    </div>
  );
};

export { AccountPage };
