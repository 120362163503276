import { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { IoIosArrowBack } from "react-icons/io";

import { Button } from "../components/button/Button";
import Modal from "../components/modal/Modal";
import { notifyError, notifySuccess } from "../components/notify";
import useLocalStorage from "../hooks/useLocalStorage";
import { reducer } from "../components/card/modalToggles";
import axiosInstance from "../axios-interceptor/axiosInterceptor";
import Dropdown from "../components/dropdown/Dropdown";
import { systemRoles } from "../helper/roles";

const UserDetailsPage = () => {
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const user = useLocalStorage("user");
  const [currentUser, setCurrentUser] = useState<any>([]);
  const [state, dispatch] = useReducer(reducer, {
    showRole: false,
    showBlock: false,
    showDelete: false,
  });

  const fetchUser = async () => {
    try {
      const res = await axiosInstance.get(`/api/user/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });

      setCurrentUser(res.data);
    } catch (err: any) {
      notifyError(err.message);
    }
  };

  const handleChangeRole = async (data: any) => {
    try {
      const response = await axiosInstance.put(
        `/api/user/${currentUser._id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.status === 200) {
        notifySuccess("Role changed successfully!");
        dispatch({ type: "toggleShowRole" });
      }
      fetchUser();
    } catch (err) {
      notifyError("Role did not change. Please try again!");
    }
  };

  const handleBlockUser = async (data: any) => {
    data.isBlocked = currentUser.isBlocked ? false : true;

    try {
      const response = await axiosInstance.put(
        `/api/user/${currentUser._id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.status === 200) {
        notifySuccess("User blocked!");
        dispatch({ type: "toggleShowBlock" });
      }
      fetchUser();
    } catch (err) {
      notifyError("Block failed. Please try again!");
    }
  };

  const handleDeleteUser = async (data: any) => {
    data.isDeleted = currentUser.isDeleted ? false : true;
    try {
      const response = await axiosInstance.put(
        `/api/user/${currentUser._id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.status === 200) {
        notifySuccess("User deleted!");
        dispatch({ type: "toggleShowDelete" });
      }
      fetchUser();
    } catch (err) {
      notifyError("Delete failed. Please try again!");
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const showRoles = () => {
    switch (currentUser.role) {
      case systemRoles.admin:
        return (
          <>
            <option value={systemRoles.servicer}>Servicer</option>
            <option value={systemRoles.accountant}>Accountant</option>
          </>
        );
      case systemRoles.servicer:
        return (
          <>
            <option value={systemRoles.admin}>Admin</option>
            <option value={systemRoles.accountant}>Accountant</option>
          </>
        );
      default:
        return (
          <>
            <option value={systemRoles.admin}>Admin</option>
            <option value={systemRoles.servicer}>Servicer</option>
          </>
        );
    }
  };

  return (
    <>
      <div
        style={{
          padding: "20px 50px",
          textAlign: "center",
        }}
      >
        <h2 style={{ marginBottom: "40px" }}>User details</h2>
        <div
          style={{
            height: "fit-content",
            display: "grid",
            gridTemplateColumns: "auto auto",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "30px",
            fontSize: "24px",
          }}
        >
          <span
            style={{
              maxWidth: "10ch",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              color: "red",
            }}
          >
            {currentUser.username}
          </span>
          <Button
            customStyle={{
              display: "flex",
              height: "fit-content",
              width: "fit-content",
              alignItems: "center",
              gap: "6px",
            }}
            onClick={() => navigate("/user")}
          >
            <IoIosArrowBack />
            <span>Back</span>
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>Email</span>
            {currentUser.email}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>Role</span>
            {currentUser.role}
          </div>

          <Button onClick={() => dispatch({ type: "toggleShowRole" })}>
            Change role
          </Button>
          <Button onClick={() => dispatch({ type: "toggleShowBlock" })}>
            {currentUser.isBlocked ? "Unblock user" : "Block user"}
          </Button>
          <Button onClick={() => dispatch({ type: "toggleShowDelete" })}>
            {currentUser.isDeleted ? "Retrieve user" : "Delete user"}
          </Button>
        </div>
      </div>
      {state.showRole && (
        <Modal
          modalHeader="Change user role"
          modalDescription="Select a user and then choose a role to change to"
          isOpen={state.showRole}
          setIsOpen={() => dispatch({ type: "toggleShowRole" })}
          onConfirm={handleSubmit(handleChangeRole)}
          leftButtonText="Change"
        >
          <>
            <Controller
              name="role"
              control={control}
              rules={{
                required: "Please enter the role of the user",
              }}
              render={({ field }) => (
                <Dropdown
                  formControl={field}
                  defaultOptionText="Select user role"
                  onSelect={(val) => console.log(val)}
                  children={showRoles()}
                />
              )}
            />
            {errors.role && (
              <span className="error-message">
                {errors.role.message as string}
              </span>
            )}
          </>
        </Modal>
      )}
      {state.showBlock && (
        <Modal
          modalHeader="Block user"
          modalDescription="The displayed users are not blocked. You can block them by selecting the user and clicking block."
          isOpen={state.showBlock}
          setIsOpen={() => dispatch({ type: "toggleShowBlock" })}
          onConfirm={handleSubmit(handleBlockUser)}
          leftButtonText={currentUser.isBlocked ? "Unblock" : "Block"}
        />
      )}
      {state.showDelete && (
        <Modal
          modalHeader={`${
            currentUser.isDeleted ? "Retrieve user" : "Delete user"
          }`}
          isOpen={state.showDelete}
          setIsOpen={() => dispatch({ type: "toggleShowDelete" })}
          onConfirm={handleSubmit(handleDeleteUser)}
          leftButtonText="Confirm"
        />
      )}
    </>
  );
};

export { UserDetailsPage };
