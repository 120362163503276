import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import styles from "../styles/CasinoPage.module.css";
import { Loading } from "../components/loading/Loading";
import { CasinoTable } from "../components/table/CasinoTable";
import { CasinoModel } from "../models/Casinos";
import { notifyError, notifySuccess } from "../components/notify";
import { Button } from "../components/button/Button";
import { useMobile } from "../hooks/useMobile";
import axiosInstance from "../axios-interceptor/axiosInterceptor";
import { NoData } from "../components/card/NoData";
import Modal from "../components/modal/Modal";
import { Input } from "../components/input/Input";
import useLocalStorage from "../hooks/useLocalStorage";

const CasinosPage = () => {
  const user = useLocalStorage("user");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [data, setData] = useState<CasinoModel[]>();
  const isMobile = useMobile();
  const [openCreateCasino, setOpenCreateCasino] = useState(false);

  const fetchCasinos = useCallback(async () => {
    try {
      const res = await axiosInstance.get("/api/casino", {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      setData(res.data);
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  }, [user]);

  const handleCreateCasino = async (data: any) => {
    try {
      await axiosInstance.post("/api/casino", data, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Casino added successfully!");
      fetchCasinos();
      setOpenCreateCasino(!openCreateCasino);
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
      setOpenCreateCasino(!openCreateCasino);
    }
  };

  useEffect(() => {
    (async () => fetchCasinos())();
  }, [fetchCasinos]);

  return (
    <>
      <div className={styles.container}>
        <h1 style={{ textAlign: "center" }}>Casinos</h1>
        <div className={styles["table-options"]}>
          <div className={styles["option-side"]}>
            <span>Machine status</span>
            <div className={styles["to-repair"]}>
              <span>Needs repair</span>
            </div>
            <div className={styles.functional}>
              <span>Functional</span>
            </div>
          </div>
          {isMobile ? (
            <div className={styles["mobile-option-side"]}>
              <Button
                customStyle={{ width: "100%", margin: "0px 20px" }}
                onClick={() => setOpenCreateCasino(!openCreateCasino)}
              >
                Add Casino
              </Button>
            </div>
          ) : (
            <div className={styles["option-side"]}>
              <Button onClick={() => setOpenCreateCasino(!openCreateCasino)}>
                Add Casino
              </Button>
            </div>
          )}
        </div>
      </div>
      {!data ? (
        <Loading />
      ) : data.length > 0 ? (
        <CasinoTable data={data} fetchCasinos={fetchCasinos} />
      ) : (
        <NoData
          title="No casinos found"
          message={`Add new casinos by clicking \"Add Casino\"`}
        />
      )}
      {openCreateCasino && (
        <Modal
          isOpen={openCreateCasino}
          setIsOpen={setOpenCreateCasino}
          onConfirm={handleSubmit(handleCreateCasino)}
          modalHeader="Add new casino"
          leftButtonText="Confirm"
          rightButtonText="Cancel"
        >
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Please enter the name of the casino",
            }}
            render={({ field }) => (
              <Input
                formControl={field}
                placeholder="Enter the name of the casino"
              />
            )}
          />
          {errors.name && (
            <span className="error-message">
              {errors.name.message as string}
            </span>
          )}
        </Modal>
      )}
    </>
  );
};

export { CasinosPage };
