import { useState, useEffect, useCallback } from "react";
import { ToastContainer } from "react-toastify";

import styles from "../styles/MachinePage.module.css";
import axiosInstance from "../axios-interceptor/axiosInterceptor";
import { NoData } from "../components/card/NoData";
import { Loading } from "../components/loading/Loading";
import { notifyError, notifySuccess } from "../components/notify";
import useLocalStorage from "../hooks/useLocalStorage";
import { MachineTable } from "../components/table/MachineTable";

import { Button } from "../components/button/Button";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../components/input/Input";
import Modal from "../components/modal/Modal";
import { useMobile } from "../hooks/useMobile";
import SearchableDropdown from "../components/dropdown/SearchableDropdown";

const MachinePage = () => {
  const user = useLocalStorage("user");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const isMobile = useMobile();
  const [data, setData] = useState<any>();
  const [openCreate, setOpenCreate] = useState(false);
  const [casinos, setCasinos] = useState<any>();
  const [valueCasinos, setValueCasinos] = useState("Select casino...");

  const fetchMachines = useCallback(async () => {
    try {
      const res = await axiosInstance.get("/api/user/machines", {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      setData(res.data);
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      await fetchMachines();
      if (openCreate) {
        fetchCasinos();
      }
    })();
  }, [openCreate, fetchMachines]);

  const fetchCasinos = async () => {
    try {
      const getUserCasinos = async () => {
        try {
          const response = await axiosInstance.get("/api/casino", {
            headers: {
              Authorization: `Bearer ${user.token}`,
              "Content-Type": "application/json",
            },
          });
          return response.data;
        } catch (err: any) {
          console.log("err", err);
          notifyError(err.response.data.message ?? err.response.data);
        }
      };

      const casinos = await getUserCasinos();
      setCasinos(casinos);
    } catch (err: any) {
      notifyError(err.message);
    }
  };

  const handleCreateMachine = async (data: any) => {
    try {
      await axiosInstance.post(`/api/machine/${data.casino}`, data, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Machine added successfully!");
      fetchMachines();
      setOpenCreate(!openCreate);
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
      setOpenCreate(!openCreate);
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: "orange", fontFamily: "Arial", fontSize: "14px" }}
      />
      <h1 style={{ textAlign: "center" }}>Machines</h1>
      {isMobile ? (
        <div className={styles["mobile-option-side"]}>
          <Button
            customStyle={{ width: "100%", margin: "0 20px" }}
            onClick={() => setOpenCreate(!openCreate)}
          >
            Add Machine
          </Button>
        </div>
      ) : (
        <div className={styles["option-side"]}>
          <Button onClick={() => setOpenCreate(!openCreate)}>
            Add Machine
          </Button>
        </div>
      )}
      {!data ? (
        <Loading />
      ) : data.length > 0 ? (
        <MachineTable data={data} fetchMachines={fetchMachines} />
      ) : (
        <>
          <NoData
            title="No machines found"
            message="Newly added machines should appear on this screen, you can create them in the Service Manager"
          />
        </>
      )}
      {openCreate && (
        <Modal
          modalHeader="Add new machine"
          modalDescription="Fill in the fields to add new machine"
          isOpen={openCreate}
          setIsOpen={setOpenCreate}
          onConfirm={handleSubmit(handleCreateMachine)}
        >
          <Controller
            name="casino"
            control={control}
            rules={{
              required: "Please select casino",
            }}
            render={({ field }) => (
              <SearchableDropdown
                field={field}
                options={casinos}
                label="name"
                id="_id"
                selectedVal={valueCasinos}
                handleChange={(val) => setValueCasinos(val ?? "")}
              />
            )}
          />
          {errors.casino && (
            <span className="error-message">
              {errors.casino.message as string}
            </span>
          )}
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Please enter the name of the machine",
            }}
            render={({ field }) => (
              <Input
                formControl={field}
                placeholder="Enter the name of the machine"
              />
            )}
          />
          {errors.name && (
            <span className="error-message">
              {errors.name.message as string}
            </span>
          )}
        </Modal>
      )}
    </>
  );
};

export { MachinePage };
