export const API_ROUTES: { [key: string]: string } = {
  login: "/api/auth/login",
  register: "/api/auth/register",
  forgot: "/api/auth/forgot",
  reset: "/api/auth/reset",
  fetchUsers: "/api/user",
};

export const FRONT_ROUTES: { [key: string]: string } = {
  landing: "/",
  login: "/login",
  register: "/register",
  forgot: "/forgot-password",
  reset: "/reset-password/:id/:token",
  home: "/home",
  service: "/service",
  serviceDetails: "/service/:id",
  casino: "/casino",
  casinoDetails: "/casino/:id",
  machineDetails: "/machine/:id",
  machine: "/machine",
  machineServices: "/machine/:id/services",
  account: "/account",
  user: "/user",
  userDetails: "/user/:id",
  userService: "/user/:id/services",
  unauthorized: "/unauthorized",
  pageNotFound: "*",
};
