import { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Button } from "../button/Button";
import styles from "./ServiceDetailsCard.module.css";
import mobileStyles from "../table/MobileTable.module.css";
import ImageDisplay from "../image-display/ImageDisplay";
import Modal from "../modal/Modal";
import axiosInstance from "../../axios-interceptor/axiosInterceptor";
import useLocalStorage from "../../hooks/useLocalStorage";
import { notifySuccess, notifyError } from "../notify";
import { systemRoles } from "../../helper/roles";
import { ServiceStatus } from "../../helper/serviceStatus";
import { formatTimestamp } from "../../helper/timestamp";

const ServiceDetailsCard = ({ data, refetchData }: any) => {
  const user = useLocalStorage("user");
  const { id } = useParams();
  const { handleSubmit } = useForm();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const getLastStatus = (actions: any) => {
    const sortedActions = actions.sort((a: any, b: any) => {
      const dateA = new Date(a.timestamp);
      const dateB = new Date(b.timestamp);

      if (dateA > dateB) return -1; // Date A is greater (more recent) than Date B
      if (dateA < dateB) return 1; // Date B is greater (more recent) than Date A
      return 0;
    });

    return sortedActions[0].actionType;
  };

  const [confirm, setConfirm] = useState(getLastStatus(data.actions));

  const handleDone = () => {
    if (
      user.role === systemRoles.superadmin ||
      user.role === systemRoles.admin ||
      user.role === systemRoles.servicer
    ) {
      changeStatus({ actionType: ServiceStatus.done });
    }
    setOpenModal(false);
  };

  const handleConfirm = () => {
    if (user.role === systemRoles.accountant) {
      changeStatus({ actionType: ServiceStatus.confirmed });
    }
    setConfirmModal(false);
  };

  const handleReject = () => {
    if (user.role === systemRoles.accountant) {
      changeStatus({ actionType: ServiceStatus.rejected });
    }
    setRejectModal(false);
  };

  const changeStatus = async (data: any) => {
    try {
      const response = await axiosInstance.put(`/api/service/${id}`, data, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setConfirm(data.actionType);
        refetchData();
        notifySuccess("Status changed successfully!");
      }
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  };

  const handleDeleteService = async () => {
    try {
      const response = await axiosInstance.delete(`/api/service/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        notifySuccess("Service deleted successfully!");
        navigate("/service");
      }
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.heading}>Service Details</h2>
          <Button
            customStyle={{
              display: "flex",
              height: "fit-content",
              width: "fit-content",
              alignItems: "center",
              gap: "6px",
            }}
            onClick={() => navigate("/service")}
          >
            <IoIosArrowBack />
            <span>Back</span>
          </Button>
        </div>
        <div className={styles.details}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <ImageDisplay serviceId={data._id} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <strong>Created by</strong>
              <span>{data.actions[0].user.username}</span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr auto",
                gridTemplateRows: "auto auto",
                gap: "12px",
                columnGap: "50px",
              }}
            >
              <strong>Machine</strong>
              <span>
                <a href={`/machine/${data.machine._id}`}>
                  {data.machine.name}{" "}
                </a>
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <strong>Description</strong>
              <p>{data.repair.name}</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <strong>Status</strong>
              {data.actions && getLastStatus(data.actions)}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <strong style={{ textAlign: "center" }}>History</strong>
              {data.actions.map((action: any) => (
                <table
                  key={action.timestamp}
                  className={mobileStyles.container}
                >
                  <tbody>
                    <tr className={mobileStyles.row}>
                      <td className={mobileStyles.headerCell}>
                        <strong>Username</strong>
                      </td>
                      <td className={mobileStyles.valueCell}>
                        <span>{action.user.username}</span>
                      </td>
                    </tr>
                    <tr className={mobileStyles.row}>
                      <td className={mobileStyles.headerCell}>
                        <strong>Action</strong>
                      </td>
                      <td className={mobileStyles.valueCell}>
                        <span>{action.actionType}</span>
                      </td>
                    </tr>
                    <tr className={mobileStyles.row}>
                      <td className={mobileStyles.headerCell}>
                        <strong>Time</strong>
                      </td>
                      <td className={mobileStyles.valueCell}>
                        <span>{formatTimestamp(action.timestamp)}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </div>

            <div
              style={{
                margin: "20px 0px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <span>
                *To be able to change the status the service needs to be
                confirmed by an accountant of the casino.
              </span>
              {user.role !== systemRoles.accountant && (
                <Button
                  disabled={
                    confirm === ServiceStatus.done ||
                    confirm === ServiceStatus.rejected ||
                    confirm === ServiceStatus.created
                  }
                  onClick={() => setOpenModal(!openModal)}
                >
                  Complete service
                </Button>
              )}

              {/* Accountant buttons */}
              {user.role === systemRoles.accountant && (
                <Button
                  disabled={
                    confirm === ServiceStatus.done ||
                    confirm === ServiceStatus.confirmed
                  }
                  onClick={() => setConfirmModal(!openModal)}
                >
                  Confirm service
                </Button>
              )}
              {user.role === systemRoles.accountant && (
                <Button
                  disabled={
                    confirm === ServiceStatus.done ||
                    confirm === ServiceStatus.rejected
                  }
                  onClick={() => setRejectModal(!openModal)}
                >
                  Reject service
                </Button>
              )}

              <Button
                onClick={() => setDeleteModal(!deleteModal)}
                customStyle={{ backgroundColor: "red" }}
              >
                Delete Service
              </Button>
            </div>
          </div>
        </div>
      </div>
      {confirmModal && (
        <Modal
          isOpen={confirmModal}
          setIsOpen={setConfirmModal}
          modalHeader="Confirm service"
          modalDescription="By clicking on confirm the service will be confirmed for repairing!"
          onConfirm={handleSubmit(handleConfirm)}
          leftButtonText="Confirm"
          rightButtonText="Cancel"
        />
      )}
      {rejectModal && (
        <Modal
          isOpen={rejectModal}
          setIsOpen={setRejectModal}
          modalHeader="Reject service"
          modalDescription="By clicking on confirm the service will be rejected for repairing!"
          onConfirm={handleSubmit(handleReject)}
          leftButtonText="Confirm"
          rightButtonText="Cancel"
        />
      )}
      {openModal && (
        <Modal
          isOpen={openModal}
          setIsOpen={setOpenModal}
          modalHeader="Complete service"
          modalDescription={`By clicking on confirm the service will be in status done. This step is irreversible!`}
          onConfirm={handleSubmit(handleDone)}
          leftButtonText="Confirm"
          rightButtonText="Cancel"
        />
      )}
      {deleteModal && (
        <Modal
          isOpen={deleteModal}
          setIsOpen={setDeleteModal}
          modalHeader="Are you sure you want to delete this service?"
          onConfirm={handleSubmit(handleDeleteService)}
          leftButtonText="Delete"
          rightButtonText="Cancel"
        />
      )}
    </>
  );
};

export { ServiceDetailsCard };
