import { Navigate, Route, Routes } from "react-router-dom";

import { FRONT_ROUTES } from "./routes/routes";
import ProtectedRoutes from "./hooks/useAuth";
import { Layout } from "./components/layouts/layout";
import ErrorPage from "./components/errors/error-page";
import { LoginPage } from "./pages/LoginPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { HomePage } from "./pages/HomePage";
import { MachineServicesPage } from "./pages/ServicesPage";
import { AccountPage } from "./pages/AccountPage";
import { UserPage } from "./pages/UserPage";
import { UserDetailsPage } from "./pages/UserDetailsPage";
import { UserServicesPage } from "./pages/UserServicesPage";
import { CasinosPage } from "./pages/CasinoPage";
import { CasinoDetailsPage } from "./pages/CasinoDetailsPage";
import { MachineDetailsPage } from "./pages/MachineDetailsPage";
import { ServiceDetailsPage } from "./pages/ServiceDetailsPage";
import { PageNotFound } from "./components/page-not-found/PageNotFound";
import { MachinePage } from "./pages/MachinePage";
import { ServicesForMachinePage } from "./pages/ServicesForMachinePage";
import useLocalStorage from "./hooks/useLocalStorage";
import { Unauthorized } from "./components/errors/Unauthorized";
import { LandingPage } from "./pages/LandingPage";

const Views = () => {
  const isAuth = useLocalStorage("user");

  return (
    <Routes>
      <Route
        path={FRONT_ROUTES.landing}
        element={
          isAuth ? <Navigate to={FRONT_ROUTES.home} replace /> : <LandingPage />
        }
      />
      <Route
        path={FRONT_ROUTES.login}
        element={
          isAuth ? <Navigate to={FRONT_ROUTES.home} replace /> : <LoginPage />
        }
      />
      <Route
        path={FRONT_ROUTES.forgot}
        element={
          isAuth ? (
            <Navigate to={FRONT_ROUTES.home} replace />
          ) : (
            <ForgotPasswordPage />
          )
        }
      />
      <Route
        path={FRONT_ROUTES.reset}
        element={
          isAuth ? (
            <Navigate to={FRONT_ROUTES.home} replace />
          ) : (
            <ResetPasswordPage />
          )
        }
      />
      <Route
        path={FRONT_ROUTES.unauthorized}
        element={
          isAuth ? (
            <Navigate to={FRONT_ROUTES.home} replace />
          ) : (
            <Unauthorized />
          )
        }
      />
      <Route element={<ProtectedRoutes />}>
        <Route
          path={FRONT_ROUTES.home}
          element={<Layout children={<HomePage />} />}
          errorElement={<ErrorPage />}
        />
        <Route
          path={FRONT_ROUTES.service}
          element={<Layout children={<MachineServicesPage />} />}
        />
        <Route
          path={FRONT_ROUTES.serviceDetails}
          element={<Layout children={<ServiceDetailsPage />} />}
        />
        <Route
          path={FRONT_ROUTES.casino}
          element={<Layout children={<CasinosPage />} />}
        />
        <Route
          path={FRONT_ROUTES.casinoDetails}
          element={<Layout children={<CasinoDetailsPage />} />}
        />
        <Route
          path={FRONT_ROUTES.machine}
          element={<Layout children={<MachinePage />} />}
        />{" "}
        <Route
          path={FRONT_ROUTES.machineServices}
          element={<Layout children={<ServicesForMachinePage />} />}
        />
        <Route
          path={FRONT_ROUTES.machineDetails}
          element={<Layout children={<MachineDetailsPage />} />}
        />
        <Route
          path={FRONT_ROUTES.account}
          element={<Layout children={<AccountPage />} />}
        />
        <Route
          path={FRONT_ROUTES.user}
          element={<Layout children={<UserPage />} />}
          errorElement={<ErrorPage />}
        />
        <Route
          path={FRONT_ROUTES.userDetails}
          element={<Layout children={<UserDetailsPage />} />}
          errorElement={<ErrorPage />}
        />
        <Route
          path={FRONT_ROUTES.userService}
          element={<Layout children={<UserServicesPage />} />}
          errorElement={<ErrorPage />}
        />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export { Views };
