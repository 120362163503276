import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

import style from "./PageNotFound.module.css";
import { Button } from "../button/Button";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className={style["not-found-container"]}>
      <div className={style["not-found-content"]}>
        <h1>404</h1>
        <p>Page Not Found</p>
        <Button
          customStyle={{
            height: "fit-content",
            width: "fit-content",
          }}
          onClick={() => navigate(-1)}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <IoIosArrowBack />
            <span>Back</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export { PageNotFound };
