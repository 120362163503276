import { useState } from "react";

const FullPictureViewer = ({ imageUrl, alt }: any) => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img
          height="200px"
          width="100%"
          src={imageUrl}
          alt={alt}
          style={{
            objectFit: "cover",
            cursor: "pointer",
          }}
          onClick={handleClick}
        />
      </div>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={closeModal} // Close modal on click
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              gap: "10px",
              padding: "10px",
              margin: "10px",
              borderRadius: "4px",
            }}
          >
            <span>Click anywhere to close picture</span>
            <img
              src={imageUrl}
              alt={alt}
              style={{
                maxHeight: "600px",
                maxWidth: "90%",
                objectFit: "contain",
                borderRadius: "4px",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FullPictureViewer;
