import React, { ReactNode } from "react";
import styles from "./Dropdown.module.css";

interface DropdownProps {
  defaultOptionText?: string;
  onSelect: (selectedItem: string) => void;
  children: ReactNode;
  formControl?: any;
}

const Dropdown: React.FC<DropdownProps> = ({
  defaultOptionText,
  children,
  formControl,
  onSelect,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedItem = event.target.value;
    onSelect(selectedItem);
  };

  return (
    <div className={styles.dropdownContainer}>
      <select
        className={styles.dropdown}
        onChange={handleChange}
        {...formControl}
      >
        <option>{defaultOptionText}</option>
        {children}
      </select>
    </div>
  );
};

export default Dropdown;
