import { useCallback, useEffect, useState } from "react";

import styles from "../styles/UserPage.module.css";
import { SearchTable } from "../components/search/SearchTable";
import { UserTable } from "../components/table/UserTable";
import axiosInstance from "../axios-interceptor/axiosInterceptor";
import { notifyError, notifySuccess } from "../components/notify";
import { Loading } from "../components/loading/Loading";
import useLocalStorage from "../hooks/useLocalStorage";
import { NoData } from "../components/card/NoData";
import { Button } from "../components/button/Button";
import { systemRoles } from "../helper/roles";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../components/dropdown/Dropdown";
import { Input } from "../components/input/Input";
import Modal from "../components/modal/Modal";
import { useMobile } from "../hooks/useMobile";

const UserPage = () => {
  const user = useLocalStorage("user");
  const isMobile = useMobile();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/api/user`, {
        headers: {
          Authorization: `Bearer ${user.token}`,

          "Content-Type": "application/json",
        },
      });
      setData(response.data);
      setLoading(false);
    } catch (error: any) {
      console.error("Error fetching machine details:", error);
      notifyError(error.response.data.message ?? error.response.data);
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      fetchUsers();
    })();
  }, []);

  if (loading) {
    return <Loading />;
  }

  const filteredData = data.filter((item: any) =>
    item.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Site korisnici vo sistemot koi se del od kazinata kade sto i jas sum del, ovaa akcija moze da ja vidat samo adminite i superadminot

  // tuka za superadmin treba da bidat site korisnici
  // za admin treba da bidat korisnicite od negovoto kazino

  const handleAddNewUser = async (data: any) => {
    try {
      await axiosInstance.post(`/api/user`, data, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      notifySuccess("User added successfully!");
      setOpenModal(!openModal);
      fetchUsers();
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <h1 style={{ textAlign: "center" }}>Users</h1>
        <div className={styles["table-options"]}>
          {isMobile ? (
            <div className={styles["mobile-option-side"]}>
              <Button
                customStyle={{ margin: "0px 20px" }}
                onClick={() => setOpenModal(!openModal)}
              >
                Add user
              </Button>
              <SearchTable
                placeholder="Search by user email"
                field="email"
                optionField="email"
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                data={filteredData}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <SearchTable
                placeholder="Search by user email"
                field="email"
                optionField="email"
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                data={filteredData}
              />

              <Button
                customStyle={{ position: "absolute" }}
                onClick={() => setOpenModal(!openModal)}
              >
                Add user
              </Button>
            </div>
          )}
        </div>
      </div>

      {!data ? (
        <Loading />
      ) : data.length > 0 ? (
        <UserTable data={filteredData} />
      ) : (
        <NoData
          title="No users found"
          message={`Add new users by clicking \"Add User\"`}
        />
      )}
      {openModal && (
        <Modal
          modalHeader="Add new user"
          modalDescription="Fill in the fields to create a user. By default the user is an admin!"
          isOpen={openModal}
          setIsOpen={() => setOpenModal(!openModal)}
          onConfirm={handleSubmit(handleAddNewUser)}
          leftButtonText="Add"
        >
          <Controller
            name="username"
            control={control}
            rules={{
              required: "Please enter the username of the user",
              maxLength: 14,
            }}
            render={({ field }) => (
              <Input
                //this should be limited to 20 characters
                formControl={field}
                placeholder="Enter the username of the user"
              />
            )}
          />
          {errors.username && (
            <span className="error-message">
              {errors.username.message as string}
            </span>
          )}
          <Controller
            name="email"
            control={control}
            rules={{
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
              required: "Please enter the email of the user",
            }}
            render={({ field }) => (
              <Input
                formControl={field}
                placeholder="Enter the email of the user"
              />
            )}
          />
          {errors.email && (
            <span className="error-message">
              {errors.email.message as string}
            </span>
          )}
          <Controller
            name="role"
            control={control}
            rules={{
              required: "Please enter the role of the user",
            }}
            render={({ field }) => (
              <Dropdown
                formControl={field}
                defaultOptionText="Select user role"
                onSelect={(val) => console.log(val)}
                children={
                  <>
                    <option value={systemRoles.admin}>Admin</option>
                    <option value={systemRoles.servicer}>Servicer</option>
                    <option value={systemRoles.accountant}>Accountant</option>
                  </>
                }
              />
            )}
          />
          {errors.role && (
            <span className="error-message">
              {errors.role.message as string}
            </span>
          )}
        </Modal>
      )}
    </>
  );
};

export { UserPage };
