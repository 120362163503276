import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import ReactPaginate from "react-paginate";

import styles from "./Table.module.css";
import mobileStyles from "./MobileTable.module.css";
import { Button } from "../button/Button";
import Modal from "../modal/Modal";
import axiosInstance from "../../axios-interceptor/axiosInterceptor";
import { notifyError, notifySuccess } from "../notify";
import { useMobile } from "../../hooks/useMobile";
import { SearchTable } from "../search/SearchTable";
import { NoData } from "../card/NoData";

interface MachineTableProps {
  data: any[];
  fetchMachines: () => void;
}

const MachineTable = ({ data, fetchMachines }: MachineTableProps) => {
  const navigate = useNavigate();
  const isMobile = useMobile();
  const [searchTerm, setSearchTerm] = useState("");

  const [openDelete, setOpenDelete] = useState(false);
  const deletingMachineIdRef = useRef<string | null>(null);

  const handleMachineDelete = async (machineId: string) => {
    try {
      await axiosInstance.delete(`/api/machine/${machineId}`);
      notifySuccess("Machine deleted successfully!");
      fetchMachines();
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  };

  const confirmDelete = () => {
    if (deletingMachineIdRef.current) {
      handleMachineDelete(deletingMachineIdRef.current);
      setOpenDelete(false);
    }
  };

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const filteredData = data.filter((item: any) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const offset = currentPage * itemsPerPage;
  const currentData = filteredData.slice(offset, offset + itemsPerPage);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: "orange", fontFamily: "Arial", fontSize: "14px" }}
      />
      <SearchTable
        placeholder="Search by machine name"
        field="name"
        data={filteredData}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <div className={styles.container}>
        {isMobile ? (
          currentData.length === 0 ? (
            <NoData
              title="Machine not found"
              message="Machine might be deleted or you do not have an machine with that particular name!"
            />
          ) : (
            currentData.map((machine: any) => (
              <table key={machine._id} className={mobileStyles.container}>
                <thead>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <th colSpan={2} className={mobileStyles.valueCell}>
                      Machine Information
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={mobileStyles.row}>
                    <td className={mobileStyles.headerCell}>Name</td>
                    <td className={mobileStyles.valueCell}>
                      <strong>{machine.name}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className={mobileStyles.headerCell}>Needs repair</td>
                    <td className={mobileStyles.valueCell}>
                      {machine.needsRepair ? "Yes" : "No"}
                    </td>
                  </tr>
                  <tr className={mobileStyles.row}>
                    <td className={mobileStyles.headerCell}>Deleted</td>
                    <td className={mobileStyles.valueCell}>
                      {machine.isDeleted ? "Yes" : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className={mobileStyles.buttonsContainer}>
                        <Button
                          onClick={() => navigate(`/machine/${machine._id}`)}
                          className={styles["button-edit"]}
                          customStyle={{
                            width: "100%",
                          }}
                        >
                          Manage
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))
          )
        ) : (
          <table className={styles.table}>
            <thead>
              <tr className={styles.headerRow}>
                <th>Name</th>
                <th>Needs Repair</th>
                <th>Deleted</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((machine: any) => (
                <tr key={machine._id} className={styles.dataRow}>
                  <td>{machine.name}</td>
                  <td>{machine.needsRepair ? "Yes" : "No"}</td>
                  <td>{machine.isDeleted ? "Yes" : "No"}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Button
                        onClick={() => navigate(`/machine/${machine._id}`)}
                        className={styles["button-edit"]}
                      >
                        Manage
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <ReactPaginate
        previousLabel={
          <Button className={styles["paggination-buttons"]}>
            <IoChevronBackOutline />
          </Button>
        }
        nextLabel={
          <Button className={styles["paggination-buttons"]}>
            <IoChevronForwardOutline />
          </Button>
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={styles.pagination}
        activeClassName={styles.active}
        pageClassName={styles.page}
        previousClassName={styles.previous}
        nextClassName={styles.next}
        breakLinkClassName={styles.breakLink}
      />
      {openDelete && (
        <Modal
          isOpen={openDelete}
          setIsOpen={setOpenDelete}
          modalHeader="Are you sure you want to delete this machine?"
          onConfirm={confirmDelete}
          leftButtonText="Confirm"
          rightButtonText="Cancel"
        />
      )}
    </>
  );
};

export { MachineTable };
