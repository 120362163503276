import { Button } from "./button/Button";
import { ToastContainer } from "react-toastify";

const Support = () => {
  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: "orange", fontFamily: "Arial", fontSize: "14px" }}
      />
      <div
        style={{
          textAlign: "center",
          padding: "30px 0",
          fontSize: "16px",
        }}
      >
        <h2>Contact technical support</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            fontWeight: "bold",
          }}
        >
          <Button
            customStyle={{
              width: "200px",
            }}
          >
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="tel:+38975944375"
            >
              Call
            </a>
          </Button>
        </div>
      </div>
    </>
  );
};

export { Support };
