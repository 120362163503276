import { useState } from "react";
import { Input } from "../input/Input";
import { Button } from "../button/Button";
import Modal from "../modal/Modal";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "../../axios-interceptor/axiosInterceptor";
import { notifyError, notifySuccess } from "../notify";
import useLocalStorage from "../../hooks/useLocalStorage";
import { FRONT_ROUTES } from "../../routes/routes";

const AuthCard = () => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [username, setUsername] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const user = useLocalStorage("user");

  const handleChangeUsername = async (data: any) => {
    try {
      data.changeUsername = true;
      const response = await axiosInstance.put(`/api/user/${user.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.status === 200) {
        const currentUser = JSON.parse(
          window.localStorage.getItem("user") || "{}"
        );

        currentUser.username = data.username;

        window.localStorage.setItem("user", JSON.stringify(currentUser));
        setUsername(false);
        notifySuccess("Username changed successfully!");
        window.location.reload();
      }
    } catch (error) {
      notifyError("Username did not change. Please try again!");
    }
  };

  const handleLogout = async () => {
    try {
      await axiosInstance.post(
        "/api/auth/revoke-token",
        { token: user.token },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      window.localStorage.clear();
      window.location.replace(FRONT_ROUTES.login);
    } catch (error) {
      console.error("Error revoking token:", error);
    }
  };

  const sendEmail = async (data: any) => {
    try {
      const response = await axiosInstance.post("/api/send-mail", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.status === 200) {
        notifySuccess(`Mail sent to ${data.email}`);
        await handleLogout();
      }
    } catch (error) {
      notifyError("Mail not sent!");
    }
  };

  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  const handleResetPassword = async (data: any) => {
    if (password !== confirmPassword) {
      notifyError("Passwords do not match!");
      return;
    }

    try {
      const response = await axiosInstance.post(
        `/auth/user/reset-password/${user.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setResetPass(false);
        notifySuccess("Password changed successfully!");
      }
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  };

  return (
    <>
      {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
          color: "orange",
          fontFamily: "Arial",
          fontSize: "14px",
        }}
      /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          padding: "30px 0",
          fontSize: "16px",

          gap: "20px",
        }}
      >
        <h2>Authentication</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0px 20px",
            gap: "14px",
          }}
        >
          <Button onClick={() => setUsername(!username)}>
            Change username
          </Button>
          <Button onClick={() => setResetPass(!resetPass)}>
            Reset password
          </Button>
          <Button onClick={() => setForgotPass(!forgotPass)}>
            Forgot password
          </Button>
        </div>
      </div>
      {username && (
        <Modal
          modalHeader="Are you sure you want to change your username?"
          isOpen={username}
          setIsOpen={setUsername}
          onConfirm={handleSubmit(handleChangeUsername)}
          leftButtonText="Change"
        >
          <Controller
            name="username"
            control={control}
            rules={{
              required: "Enter new username",
              maxLength: 14,
            }}
            render={({ field }) => (
              <Input
                formControl={field}
                placeholder="Enter your new username"
              />
            )}
          />
          {errors.username && (
            <span className="error-message">
              {errors.username.message as string}
            </span>
          )}
        </Modal>
      )}
      {forgotPass && (
        <Modal
          modalHeader="Request password change"
          modalDescription="Fill in your email and expect a mail to be delivered to your mail provider, please also check the spam folder!"
          isOpen={forgotPass}
          setIsOpen={setForgotPass}
          onConfirm={handleSubmit(sendEmail)}
          leftButtonText="Request"
        >
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
            render={({ field }) => (
              <Input formControl={field} placeholder="Enter your email" />
            )}
          />
          {errors.email && (
            <span className="error-message">
              {errors.email.message as string}
            </span>
          )}
        </Modal>
      )}
      {resetPass && (
        <Modal
          modalHeader="Reset your password"
          modalDescription="Enter the following fields to reset your password"
          isOpen={resetPass}
          setIsOpen={setResetPass}
          onConfirm={handleSubmit(handleResetPassword)}
          leftButtonText="Reset"
        >
          <Controller
            name="oldPassword"
            control={control}
            defaultValue=""
            rules={{
              required: "Old password is required",
            }}
            render={({ field }) => (
              <>
                <Input
                  inputType="password"
                  formControl={field}
                  placeholder="Enter old password"
                />
              </>
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: "Password is required",
              validate: (value) =>
                value === confirmPassword || "Passwords do not match",
            }}
            render={({ field }) => (
              <>
                <Input
                  inputType="password"
                  formControl={field}
                  placeholder="Enter new password"
                />
              </>
            )}
          />
          {errors.password && (
            <span className="error-message">
              {errors.password.message as string}
            </span>
          )}
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            rules={{
              required: "Confirm password is required",
              validate: (value) =>
                value === password || "Passwords do not match",
            }}
            render={({ field }) => (
              <Input
                inputType="password"
                formControl={field}
                placeholder="Enter your password again"
              />
            )}
          />
          {errors.confirmPassword && (
            <span className="error-message">
              {errors.confirmPassword.message as string}
            </span>
          )}
        </Modal>
      )}
    </>
  );
};

export { AuthCard };
