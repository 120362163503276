import { useRef } from "react";

import styles from "../styles/Landing.module.css";
import Brand from "../components/brand/Brand";
import { useMobile } from "../hooks/useMobile";

const LandingPage = () => {
  const isMobile = useMobile();

  const aboutRef: any = useRef(null);
  const contactRef: any = useRef(null);

  const handleScrollToAbout = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return isMobile ? (
    <>
      <div className={styles.landingContainer}>
        <div className={styles.nav}>
          <a href="/login" className={styles["login-link"]}>
            Login
          </a>
          <span onClick={handleScrollToAbout} style={{ cursor: "pointer" }}>
            About
          </span>
          <span onClick={handleScrollToContact} style={{ cursor: "pointer" }}>
            Contact
          </span>
        </div>
        <div className={styles.innerContainer}>
          <Brand height={100} width={300} />
          <div className={styles.casinoImages}>
            <img
              height={400}
              width="auto"
              src="/CasinoLaptop.svg"
              alt="Casino Laptop"
            />
            <img
              className={styles["casino-phone"]}
              height="auto"
              width={80}
              src="/CasinoPhone.svg"
              alt="Casino Phone"
            />
          </div>
        </div>
        <div ref={aboutRef} className={styles.gridContainer}>
          <div className={styles.gridItem}>
            <h1>Streamlined Casino Management</h1>
            <ul>
              <li>
                Effortless Machine Tracking: Monitor your casino floor and
                individual machines with ease.
              </li>
              <li>
                Real-Time Data Insights: Gain valuable insights to optimize
                machine performance and profitability.
              </li>
              <li>
                Simplified Maintenance Management: Schedule and track machine
                servicing for maximum uptime.
              </li>
              <li>
                Centralized Control Panel: Manage all your casinos and machines
                from a single platform. pen_spark
              </li>
            </ul>
          </div>
          <div className={styles.imageContainer}>
            <img
              height={300}
              width={400}
              src="/PhoneLaptop.svg"
              alt="Phone and Laptop"
            />
          </div>

          <div className={styles.gridItem}>
            <h1>Powerful Features for Enhanced Control</h1>
            <ul>
              <li>
                Seamless Machine Movement: Effortlessly transfer machines
                between casinos as needed.
              </li>
              <li>
                Detailed Audit Logs: Track all machine activity and user
                interactions for complete transparency. pen_spark
              </li>
              <li>
                Customizable Reports: Generate reports tailored to your specific
                needs and data analysis. pen_spark
              </li>
              <li>
                Secure Access Management: Control user access and permissions
                for optimal security. pen_spark
              </li>
            </ul>
          </div>
          <div className={styles.imageContainerRight}>
            <img
              height={300}
              width={400}
              src="/SlotMachines.svg"
              alt="Slot machines"
            />
          </div>
          <div className={styles.gridItem}>
            <h1>Our clients</h1>
            <p>
              We listen to our clients' needs, any ideas you have about
              improvements do not hesitate to share on h.vangel22@gmail.com
            </p>
          </div>
          <div className={styles.imageContainer}>
            <img height={300} width={400} src="/Avatar.svg" alt="Avatar" />
          </div>
        </div>
      </div>
      <div ref={contactRef} className={styles.footer}>
        2024 © Vangel Hristov
      </div>
    </>
  ) : (
    <>
      <div className={styles.landingContainer}>
        <div className={styles.nav}>
          <a href="/login" className={styles["login-link"]}>
            Login
          </a>
          <span onClick={handleScrollToAbout} style={{ cursor: "pointer" }}>
            About
          </span>
          <span onClick={handleScrollToContact} style={{ cursor: "pointer" }}>
            Contact
          </span>
        </div>
        <div className={styles.innerContainer}>
          <img
            className={styles.slotHandleImage}
            src="/SlotHandle.svg"
            alt="Slot handle"
          />
          <Brand height={200} width={400} />

          <div className={styles.casinoImages}>
            <img
              height={400}
              width="auto"
              src="/CasinoLaptop.svg"
              alt="Casino Laptop"
            />
            <img
              height={300}
              width="auto"
              src="/CasinoPhone.svg"
              alt="Casino Phone"
            />
          </div>
        </div>
        <div ref={aboutRef} className={styles.gridContainer}>
          <div className={styles.gridItem}>
            <h1>Streamlined Casino Management</h1>
            <ul>
              <li>
                Effortless Machine Tracking: Monitor your casino floor and
                individual machines with ease.
              </li>
              <li>
                Real-Time Data Insights: Gain valuable insights to optimize
                machine performance and profitability.
              </li>
              <li>
                Simplified Maintenance Management: Schedule and track machine
                servicing for maximum uptime.
              </li>
              <li>
                Centralized Control Panel: Manage all your casinos and machines
                from a single platform. pen_spark
              </li>
            </ul>
          </div>
          <div className={styles.imageContainer}>
            <img
              height={300}
              width={400}
              src="/PhoneLaptop.svg"
              alt="Phone and Laptop"
            />
          </div>
          <div className={styles.imageContainerRight}>
            <img
              height={300}
              width={400}
              src="/SlotMachines.svg"
              alt="Slot machines"
            />
          </div>
          <div className={styles.gridItem}>
            <h1>Powerful Features for Enhanced Control</h1>
            <ul>
              <li>
                Seamless Machine Movement: Effortlessly transfer machines
                between casinos as needed.
              </li>
              <li>
                Detailed Audit Logs: Track all machine activity and user
                interactions for complete transparency. pen_spark
              </li>
              <li>
                Customizable Reports: Generate reports tailored to your specific
                needs and data analysis. pen_spark
              </li>
              <li>
                Secure Access Management: Control user access and permissions
                for optimal security. pen_spark
              </li>
            </ul>
          </div>
          <div className={styles.gridItem}>
            <h1>Our clients</h1>
            <p>
              We listen to our clients' needs, any ideas you have about
              improvements do not hesitate to share on h.vangel22@gmail.com
            </p>
          </div>
          <div className={styles.imageContainer}>
            <img height={300} width={400} src="/Avatar.svg" alt="Avatar" />
          </div>
        </div>
      </div>
      <div ref={contactRef} className={styles.footer}>
        2024 © Vangel Hristov
      </div>
    </>
  );
};

export { LandingPage };
