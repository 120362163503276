import { useState, useEffect, useRef, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams, useNavigate, Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

import styles from "../styles/CasinoDetails.module.css";
import axiosInstance from "../axios-interceptor/axiosInterceptor";
import { notifyError, notifySuccess } from "../components/notify";
import { CasinoModel } from "../models/Casinos";
import { Loading } from "../components/loading/Loading";
import { Button } from "../components/button/Button";
import Modal from "../components/modal/Modal";
import { Input } from "../components/input/Input";
import { ToastContainer } from "react-toastify";
import useLocalStorage from "../hooks/useLocalStorage";
import { NoData } from "../components/card/NoData";

const CasinoDetailsPage = () => {
  const user = useLocalStorage("user");
  const { id } = useParams();
  const navigate = useNavigate();
  const [casino, setCasino] = useState<CasinoModel>();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [changeName, setChangeName] = useState(false);
  const [machineModal, setMachineModal] = useState(false);
  const [memberModal, setMemberModal] = useState(false);
  const [deleteMachine, setDeleteMachine] = useState(false);
  const [deleteMember, setDeleteMember] = useState(false);

  const deletingMachineRef = useRef<string | null>(null);
  const deletingMemberRef = useRef<string | null>(null);

  const fetchCasinoDetails = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/api/casino/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      setCasino(response.data);
      setLoading(false);
    } catch (error: any) {
      console.error("Error fetching casino details:", error);
      notifyError(error.response.data.message ?? error.response.data);
      setLoading(false);
    }
  };

  const handleAddMachine = useCallback(
    async (data: any) => {
      try {
        await axiosInstance.post(`/api/machine/${id}`, data, {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        });
        notifySuccess("Machine added successfully!");
        setMachineModal(false);
        fetchCasinoDetails();
      } catch (err: any) {
        console.log("err", err);
        notifyError(err.response.data.message ?? err.response.data);
      }
    },
    [id, user]
  );

  const handleDeleteMachine = async (machineId: string) => {
    try {
      await axiosInstance.delete(`/api/casino/${id}/machine/${machineId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Machine deleted successfully!");
      fetchCasinoDetails();
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  };

  const handleDeleteMember = async (userId: string) => {
    try {
      await axiosInstance.delete(`/api/casino/${id}/member/${userId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Member deleted successfully!");
      fetchCasinoDetails();
    } catch (err: any) {
      console.log("err", err);
      notifyError(err.response.data.message ?? err.response.data);
    }
  };

  const confirmDeleteMember = () => {
    if (deletingMemberRef.current) {
      handleDeleteMember(deletingMemberRef.current);
    }
  };

  const confirmDeleteMachine = () => {
    if (deletingMachineRef.current) {
      handleDeleteMachine(deletingMachineRef.current);
    }
  };

  const handleAddMember = useCallback(
    async (data: any) => {
      try {
        await axiosInstance.post(`/api/casino/${id}/member`, data, {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        });
        notifySuccess("Member added successfully!");
        setMemberModal(false);
        fetchCasinoDetails();
      } catch (err: any) {
        console.log("err", err);
        notifyError(err.response.data.message ?? err.response.data);
      }
    },
    [id, user]
  );

  const handleChangeCasinoName = async (data: any) => {
    try {
      await axiosInstance.put(`/api/casino/${id}`, data, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Casino name changed successfully!");
      setChangeName(false);
      fetchCasinoDetails();
    } catch (error: any) {
      console.error("Error updating casino name:", error);
      notifyError(error.response.data.message ?? error.response.data);
    }
  };

  const handleRetrieveCasino = async () => {
    try {
      await axiosInstance.get(`/api/casino/retrieve/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Casino retrieved successfully!");
      fetchCasinoDetails();
    } catch (error: any) {
      console.error("Error undeleting casino:", error);
      notifyError(error.response.data.message ?? error.response.data);
    }
  };

  useEffect(() => {
    fetchCasinoDetails();
  }, [id, user, navigate]);

  if (loading) {
    return <Loading />;
  }

  if (!casino) {
    return (
      <NoData
        title="Casino not found"
        message="The casino you are trying to reach might be deleted."
      />
    );
  }

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: "orange", fontFamily: "Arial", fontSize: "14px" }}
      />
      <div className={styles.container}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "30px",
          }}
        >
          <h2 className={styles.heading}>Casino Details</h2>
          <Button
            customStyle={{
              display: "flex",
              height: "fit-content",
              width: "fit-content",
              alignItems: "center",
              gap: "6px",
            }}
            onClick={() => navigate("/casino")}
          >
            <IoIosArrowBack />
            <span>Back</span>
          </Button>
        </div>
        <div className={styles.details}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr auto",
                gridTemplateRows: "auto auto",
                gap: "12px",
                columnGap: "50px",
              }}
            >
              <strong>Name</strong>
              <span>{casino.name}</span>
              {!casino.isDeleted && !changeName && (
                <Button
                  onClick={() => setChangeName(true)}
                  customStyle={{ gridColumn: "span 2" }}
                >
                  Change Name
                </Button>
              )}
            </div>

            {changeName && (
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
                onSubmit={handleSubmit(handleChangeCasinoName)}
              >
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: "Please enter new name of the casino",
                  }}
                  render={({ field }) => (
                    <Input
                      style={{ width: "95%" }}
                      formControl={field}
                      placeholder="Rename casino"
                    />
                  )}
                />
                {errors.name && (
                  <span className="error-message">
                    {errors.name.message as string}
                  </span>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Button customStyle={{ width: "100%" }} type="submit">
                    Confirm
                  </Button>
                  <Button
                    customStyle={{ width: "100%", background: "red" }}
                    onClick={() => setChangeName(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            )}
          </div>
          <p>
            <strong>Members</strong>
          </p>
          <ul className={styles["member-list"]}>
            {casino.members &&
              casino.members.map((member: any) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px 0",
                  }}
                >
                  {user.username === member.username ? (
                    <span>{member.username}</span>
                  ) : (
                    <Link to={`/user/${member._id}`}>{member.username}</Link>
                  )}
                  {!casino.isDeleted && user.username !== member.username && (
                    <Button
                      onClick={() => {
                        setDeleteMember(!deleteMember);
                        deletingMemberRef.current = member._id;
                      }}
                      customStyle={{ backgroundColor: "red" }}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              ))}
          </ul>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto",
              gap: "20px",
            }}
          >
            {!casino.isDeleted && (
              <Button onClick={() => setMemberModal(!memberModal)}>
                Add Member
              </Button>
            )}
          </div>
          <p>
            <strong>Machines</strong>
          </p>
          <ul className={styles["machines-list"]}>
            {casino.machines &&
              casino.machines.map((machine: any) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px 0",
                  }}
                >
                  <li key={machine._id}>
                    <Link to={`/machine/${machine._id}`}>{machine.name}</Link>
                  </li>
                  {!casino.isDeleted && (
                    <Button
                      onClick={() => {
                        setDeleteMachine(!deleteMachine);
                        deletingMachineRef.current = machine._id;
                      }}
                      customStyle={{ backgroundColor: "red" }}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              ))}
          </ul>
          <p>{casino.isDeleted && "Retrieve"}</p>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto",
              gap: "20px",
            }}
          >
            {!casino.isDeleted && (
              <Button onClick={() => setMachineModal(!machineModal)}>
                Add Machine
              </Button>
            )}
            {casino.isDeleted && (
              <Button onClick={handleRetrieveCasino}>Retrieve Casino</Button>
            )}
          </div>
        </div>
      </div>
      {machineModal && (
        <Modal
          isOpen={machineModal}
          setIsOpen={setMachineModal}
          modalHeader="Add new machine for this casino"
          onConfirm={handleSubmit(handleAddMachine)}
          leftButtonText="Confirm"
          rightButtonText="Cancel"
        >
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Please enter the name of the machine",
            }}
            render={({ field }) => (
              <Input
                formControl={field}
                placeholder="Enter the name of the machine"
              />
            )}
          />
          {errors.name && (
            <span className="error-message">
              {errors.name.message as string}
            </span>
          )}
        </Modal>
      )}
      {memberModal && (
        <Modal
          isOpen={memberModal}
          setIsOpen={setMemberModal}
          modalHeader="Add new member for this casino"
          modalDescription={`The role of the user will be inherited as he was created by your superadmin.`}
          onConfirm={handleSubmit(handleAddMember)}
          leftButtonText="Confirm"
          rightButtonText="Cancel"
        >
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Please enter the email of the member",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
            render={({ field }) => (
              <Input
                formControl={field}
                placeholder="Enter the email of the member"
              />
            )}
          />
          {errors.email && (
            <span className="error-message">
              {errors.email.message as string}
            </span>
          )}
        </Modal>
      )}
      {deleteMachine && (
        <Modal
          isOpen={deleteMachine}
          setIsOpen={setDeleteMachine}
          modalHeader="Are you sure you want to remove this machine"
          onConfirm={confirmDeleteMachine}
          leftButtonText="Confirm"
          rightButtonText="Cancel"
        />
      )}
      {deleteMember && (
        <Modal
          isOpen={deleteMember}
          setIsOpen={setDeleteMember}
          modalHeader="Are you sure you want to remove this member"
          onConfirm={confirmDeleteMember}
          leftButtonText="Confirm"
          rightButtonText="Cancel"
        />
      )}
    </>
  );
};

export { CasinoDetailsPage };
