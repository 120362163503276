import styles from "./Input.module.css";

interface InputProps {
  placeholder?: string;
  inputType?: string;
  formControl?: any;
  style?: any;
}

export const Input = ({
  formControl,
  placeholder,
  inputType,
  style,
}: InputProps) => {
  return (
    <input
      {...formControl}
      type={inputType}
      className={styles.input}
      style={style}
      placeholder={placeholder}
    />
  );
};
