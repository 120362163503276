import React, { useEffect, useRef, useState } from "react";
import styles from "./SearchableDropdown.module.css";

interface Option {
  [key: string]: any;
}

interface SearchableDropdownProps {
  options: Option[];
  label: string;
  id: string;
  selectedVal: string;
  selectedId?: (option: string | null) => void;
  handleChange: (option: string | null) => void;
  field?: any;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  options,
  label,
  id,
  selectedVal,
  selectedId,
  field,
  handleChange,
}) => {
  const [query, setQuery] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option: Option) => {
    setQuery("");
    handleChange(option[label]);
    if (selectedId) selectedId(option["_id"]);

    // Get the ids of the selected options
    // for machine and repair description

    field.value = option["_id"];
    field.onChange(field.value);

    setIsOpen((isOpen) => !isOpen);
  };

  const toggle = (e: any) => {
    setIsOpen(!!(e && inputRef.current && e.target === inputRef.current));
  };

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;

    return "";
  };

  const filter = (options: Option[]) => {
    return options?.filter(
      (option) => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  return (
    <div className={styles.dropdown}>
      <div className={styles.control}>
        <div className={styles["selected-value"]}>
          <input
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              selectedId && selectedId(null);
              handleChange(null);
              field.onChange();
            }}
            onClick={toggle}
            placeholder="Search..."
          />
        </div>
        <div className={`${styles.arrow} ${isOpen ? styles.open : ""}`}></div>
      </div>

      <div className={`${styles.options} ${isOpen ? styles.open : ""}`}>
        {!filter(options) ? (
          <span className={`${styles.option}`}>No data found</span>
        ) : (
          filter(options)?.map((option, index) => {
            return (
              <div
                onClick={() => selectOption(option)}
                className={`${styles.option} ${
                  option[label] === selectedVal ? styles.selected : ""
                }`}
                key={`${id}-${index}`}
              >
                {option[label]}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default SearchableDropdown;
