import { ReactNode } from "react";
import Navbar from "../nav/Navbar";

type LayoutProps = {
  children: ReactNode;
  footer?: boolean;
};

const Layout = ({ children, footer = false }: LayoutProps) => {
  return (
    <>
      <Navbar />
      <main
        style={{
          margin: "10px 0px",
        }}
      >
        {children}
      </main>
      {/* {footer && <Footer />} */}
    </>
  );
};

export { Layout };
