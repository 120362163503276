import { useState } from "react";
import { IoMdMenu } from "react-icons/io";
import { CiCircleChevDown, CiCircleChevUp, CiLogout } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";

import styles from "./Navbar.module.css";
import { useMobile } from "../../hooks/useMobile";
import { IconButton } from "../button/IconButton";
import NavbarDropdown from "../dropdown/NavbarDropdown";
import { useNavigate } from "react-router-dom";
import { FRONT_ROUTES } from "../../routes/routes";
import axiosInstance from "../../axios-interceptor/axiosInterceptor";
import { RxAvatar } from "react-icons/rx";
import useLocalStorage from "../../hooks/useLocalStorage";
import { systemRoles } from "../../helper/roles";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [userMenu, setUserMenu] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMobile();

  const user = useLocalStorage("user");

  const handleLogout = async () => {
    try {
      await axiosInstance.post(
        "/api/auth/revoke-token",
        { token: user.token },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      window.localStorage.clear();
      window.location.replace(FRONT_ROUTES.login);
    } catch (error) {
      console.error("Error revoking token:", error);
    }
  };

  const toggleMenu = () => {
    if (userMenu) {
      setIsOpen((prevState) => !prevState);
      setUserMenu((prevState) => !prevState);
    } else {
      setIsOpen((prevState) => !prevState);
    }
  };

  const toggleUserMenu = () => {
    if (isOpen) {
      setIsOpen((prevState) => !prevState);
      setUserMenu((prevState) => !prevState);
    } else {
      setUserMenu((prevState) => !prevState);
    }
  };

  const handleSelectedOption = (option: string) => {
    console.log("option", option);
    if (Object.values(FRONT_ROUTES).includes(option)) {
      setUserMenu(false);
      setIsOpen(false);
      if (option === FRONT_ROUTES.login) {
        handleLogout();
      } else {
        navigate(option);
      }
    }
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbar__container}>
        {isMobile ? (
          <>
            <div className={styles.navbar_mobile}>
              <div>
                <div
                  id="account"
                  onClick={toggleUserMenu}
                  className={styles.navbar__logo_container}
                >
                  <RxAvatar size={40} />
                  <h1 className={styles.navbarText}>{user.username}</h1>
                  {userMenu ? (
                    <CiCircleChevUp size={20} />
                  ) : (
                    <CiCircleChevDown size={20} />
                  )}
                </div>
                {userMenu && (
                  <NavbarDropdown
                    setUserMenu={setUserMenu}
                    onSelect={handleSelectedOption}
                    data={[
                      {
                        name: "Account Settings",
                        route: FRONT_ROUTES.account,
                        icon: <IoSettingsOutline />,
                      },
                      {
                        name: "Log out",
                        route: FRONT_ROUTES.login,
                        icon: <CiLogout />,
                      },
                    ]}
                  />
                )}
              </div>
              <IconButton onClick={toggleMenu}>
                <IoMdMenu />
              </IconButton>
            </div>
            {isOpen && (
              <div className={`${styles.navbar__menu} `}>
                {user.role === systemRoles.superadmin && (
                  <>
                    <a href="/casino">Casinos</a>
                    <a href="/machine">Machines</a>
                    <a href="/home">Service Manager</a>
                    <a href="/service">View machine services</a>
                    <a href="/user">Users</a>
                  </>
                )}

                {user.role === systemRoles.admin && (
                  <>
                    <a href="/casino">Casinos</a>
                    <a href="/machine">Machines</a>
                    <a href="/home">Service Manager</a>
                    <a href="/service">View machine services</a>
                  </>
                )}

                {user.role === systemRoles.servicer && (
                  <>
                    <a href="/machine">Machines</a>
                    <a href="/home">Service Manager</a>
                    <a href="/service">View machine services</a>
                  </>
                )}

                {user.role === systemRoles.accountant && (
                  <>
                    <a href="/service">View machine services</a>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <div style={{ position: "relative" }}>
              <div
                id="account"
                onClick={toggleUserMenu}
                className={styles.navbar__logo_container}
              >
                <RxAvatar size={50} />
                <h1 className={styles.navbarText}>{user.username}</h1>
                {userMenu ? (
                  <CiCircleChevUp size={24} />
                ) : (
                  <CiCircleChevDown size={24} />
                )}
              </div>
              {userMenu && (
                <NavbarDropdown
                  setUserMenu={setUserMenu}
                  onSelect={handleSelectedOption}
                  data={[
                    {
                      name: "Account Settings",
                      route: FRONT_ROUTES.account,
                      icon: <IoSettingsOutline />,
                    },
                    {
                      name: "Log out",
                      route: FRONT_ROUTES.login,
                      icon: <CiLogout />,
                    },
                  ]}
                />
              )}
            </div>
            <div className={`${styles.navbar__menu} ${styles.active}`}>
              {user.role === systemRoles.superadmin && (
                <>
                  <a href="/casino">Casinos</a>
                  <a href="/machine">Machines</a>
                  <a href="/home">Service Manager</a>
                  <a href="/service">View machine services</a>
                  <a href="/user">Users</a>
                </>
              )}

              {user.role === systemRoles.admin && (
                <>
                  <a href="/casino">Casinos</a>
                  <a href="/machine">Machines</a>
                  <a href="/home">Service Manager</a>
                  <a href="/service">View machine services</a>
                </>
              )}

              {user.role === systemRoles.servicer && (
                <>
                  <a href="/machine">Machines</a>
                  <a href="/home">Service Manager</a>
                  <a href="/service">View machine services</a>
                </>
              )}

              {user.role === systemRoles.accountant && (
                <>
                  <a href="/service">View machine services</a>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
