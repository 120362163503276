import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Controller, useForm } from "react-hook-form";

import styles from "./ForgotPassword.module.css";
import Brand from "../brand/Brand";
import { Button } from "../button/Button";
import { FRONT_ROUTES } from "../../routes/routes";
import axiosInstance from "../../axios-interceptor/axiosInterceptor";
import { notifySuccess, notifyError } from "../notify";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const sendEmail = async (data: any) => {
    try {
      const response = await axiosInstance.post("/api/send-mail", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        notifySuccess(`Mail sent to ${data.email}`);
      }
    } catch (error) {
      notifyError("Mail not sent!");
    }
  };

  const handleBackToLogin = () => {
    navigate(FRONT_ROUTES.login);
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ color: "orange", fontFamily: "Arial", fontSize: "14px" }}
      />
      <div className={styles["forgot-password-page"]}>
        <div className={styles["forgot-password-container"]}>
          <h2 className={styles["forgot-password-header"]}>
            <Brand height={80} width={250} />
          </h2>
          <form
            className={styles["forgot-password-form"]}
            onSubmit={handleSubmit(sendEmail)}
          >
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <>
                  <br />
                  <input
                    {...field}
                    className={styles["forgot-password-input"]}
                    placeholder="Email"
                    type="email"
                    id="email"
                    name="email"
                  />
                </>
              )}
            />
            {errors.email && (
              <span className="error-message">
                {errors.email.message as string}
              </span>
            )}
            <Button type="submit">Request password change</Button>
          </form>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button onClick={handleBackToLogin}>Back to Login</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
