import { ReactNode } from "react";
import styles from "./Button.module.css";

interface ButtonProps {
  children: ReactNode;
  type?: "submit" | "reset" | "button" | undefined;
  onClick?: () => void;
  customStyle?: any;
  form?: string;
  id?: string;
  className?: any;
  disabled?: boolean;
}

export const Button = ({
  id,
  children,
  type = undefined,
  form,
  customStyle,
  className,
  disabled,
  onClick,
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      id={id}
      type={type}
      form={form}
      className={className ?? styles.button}
      style={customStyle}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
