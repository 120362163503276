import { useRef, useEffect, ReactNode } from "react";
import { useMobile } from "../../hooks/useMobile";

interface AccountOptions {
  name: string;
  route: string;
  icon?: ReactNode;
}

interface NavbarDropdownProps {
  data: AccountOptions[];
  onSelect: (item: any) => void;
  setUserMenu: (userMenu: boolean) => void;
}

const NavbarDropdown = ({
  data,
  onSelect,
  setUserMenu,
}: NavbarDropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isMobile = useMobile();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setUserMenu(false);
      }
    };

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return isMobile ? (
    <div
      ref={dropdownRef}
      style={{
        width: "200px",
        position: "absolute",
        top: 60,
        left: 20,
        backgroundColor: "white",
        color: "#2C3E50",
        borderRadius: "4px",
        border: "1px solid #2C3E50",
        zIndex: 999,
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "20px",
      }}
    >
      {data.map((item, index) => (
        <div
          key={index}
          onClick={() => onSelect(item.route)}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {item.name}
          {item.icon}
        </div>
      ))}
    </div>
  ) : (
    <div
      ref={dropdownRef}
      style={{
        width: "100%",
        position: "absolute",
        top: "100%",
        left: 0,
        backgroundColor: "white",
        color: "#2C3E50",
        borderRadius: "4px",
        border: "1px solid #2C3E50",
        zIndex: 999,
        display: "flex",
        flexDirection: "column",
        gap: "14px",
        padding: "10px",
      }}
    >
      {data.map((item, index) => (
        <div
          key={index}
          onClick={() => onSelect(item.route)}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {item.name}
          {item.icon}
        </div>
      ))}
    </div>
  );
};

export default NavbarDropdown;
