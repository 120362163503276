import { useState, useEffect, useRef } from "react";
import debounce from "../../helper/debouce";
import styles from "./Search.module.css";
interface SearchTableProps {
  data: any;
  placeholder: string;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  field: string;
  optionField?: string;
}

const SearchTable = ({
  data,
  placeholder,
  searchTerm,
  setSearchTerm,
  field,
  optionField = "name",
}: SearchTableProps) => {
  const [machine, setMachine] = useState<any[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const debouncedFilterData = debounce((inputValue: string) => {
    const filterData = data.filter((d: any) =>
      d[field].toLowerCase().includes(inputValue.toLowerCase())
    );
    setMachine(filterData);
  }, 400); // Debounce delay for filtering

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);
    setShowSuggestions(true);
    debouncedFilterData(inputValue);

    const isExactMatch = data.some(
      (d: any) => d[field].toLowerCase() === inputValue.toLowerCase()
    );

    if (inputValue === "") {
      setShowSuggestions(false);
    }

    if (isExactMatch) {
      setShowSuggestions(false);
    }
  };

  const handleOptionClick = (option: string) => {
    setSearchTerm(option);
    setShowSuggestions(false);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target as Node)
    ) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.container} ref={containerRef}>
      <input
        value={searchTerm}
        onChange={handleInputChange}
        className={styles.input}
        placeholder={placeholder}
      />
      {showSuggestions && (
        <div className={styles.suggestionsContainer}>
          {machine.length > 0 && (
            <div className={styles.suggestions}>
              {machine.slice(0, 5).map((option) => (
                <div
                  key={option._id}
                  onClick={() => handleOptionClick(option[optionField])}
                  className={styles.suggestion}
                >
                  {option[optionField]}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { SearchTable };
